const mapFiltersFromUrl = filter => {
  let decodedFilter = undefined
  try {
    decodedFilter = decodeURIComponent(filter)
  } catch (ex) {
    decodedFilter = undefined
  }
  const checkedFilters = {}
  if (decodedFilter) {
    const temp = decodedFilter.split('&')
    temp.forEach(keyVal => {
      const [key, value] = keyVal.split(':')
      if (value) {
        checkedFilters[key] = value.split(',')
      }
    })
  }

  return checkedFilters
}

function mapFiltersFromUrlToCSV(filter) {
  const checkedFilters = {}
  if (filter) {
    const temp = filter.split('&')
    temp.forEach(keyVal => {
      const [key, value] = keyVal.split(':')
      if (value) {
        checkedFilters[key] = value
      }
    })
  }

  return checkedFilters
}

const priceFilterLabel = obj => {
  if (parseFloat(obj.start) === 0.0) {
    return `<$${parseFloat(obj.end).toString()}`
  } else if (parseFloat(obj.end) === 0.0) {
    return `>$${parseFloat(obj.start).toString()}`
  } else {
    return (
      '$' +
      parseFloat(obj.start).toString() +
      ' - $' +
      parseFloat(obj.end).toString()
    )
  }
}

const convertFilters = filters => {
  let filterString = Object.keys(filters).reduce((acc, curr) => {
    acc = acc + `${curr}:${filters[curr].join(',')}&`
    return acc
  }, '')

  filterString = filterString.slice(0, filterString.length - 1)
  return encodeURIComponent(filterString)
}

const generateQueryString = (object, shouldNotDecode = false) => {
  if (!object || Object.keys(object).length === 0) {
    return ''
  }

  // excludimg the params that are not required for api call
  Object.keys(object).forEach(key => {
    if (
      [
        'exclude_ga_track_id',
        'exclude_ga_track_name',
        'loc',
        'layoutType',
        'loadMoreType',
      ].includes(key)
    ) {
      delete object[key]
    }
  })

  return Object.keys(object).length
    ? '?' +
        Object.keys(object)
          .filter(key => object[key])
          .map(
            key =>
              `${key}=${
                shouldNotDecode
                  ? encodeURIComponent(object[key])
                  : decodeURIComponent(encodeURIComponent(object[key]))
              }`
          )
          .join('&')
    : ''
}

function modifyFormat(data) {
  const copyOfData = { ...data }
  let keys = Object.keys(copyOfData)
  const newData = {},
    sortedData = {}
  // filter keys which has falsy values

  keys = keys.filter(key => copyOfData[key] && copyOfData[key].length)

  keys.forEach(key => {
    // sort the data aplhabateically
    sortedData[key] =
      copyOfData[key] &&
      copyOfData[key].sort((A, B) =>
        key === 'price' ? A.start - B.start : A.slug.localeCompare(B.slug)
      )
    // changing the sorted data in desired format
    newData[key] = sortedData[key].map(elm => ({
      ...elm,
      label: key !== 'price' ? elm.name || elm.slug : priceFilterLabel(elm),
    }))
  })

  return newData
}

function removeNullValues(data) {
  const copyOfData = { ...data }
  let keys = Object.keys(copyOfData)
  const newData = {}

  keys = keys.filter(key => copyOfData[key] && copyOfData[key].length)

  keys.forEach(key => {
    newData[key] = copyOfData[key].map(elm => ({
      ...elm,
    }))
  })

  return newData
}

const TagSortingMap = {
  '3 day specials': 3,
  '3 days specials': 3,

  '2 day specials': 2,
  '2 days specials': 2,

  '1 day specials': 1,
  '1 days specials': 1,
  pwp: 5,
  'purchase with purchase': 5,
  'weekly deals': 6,
  'value deals': 7,
  'clearance sale': 8,
  'carton deals': 9,
  'free gift with purchase': 10,
}

const tagFilterSorting = (tags = []) => {
  if (Array.isArray(tags)) {
    const tagCopy = [...tags]
    return tagCopy.sort(
      (a, b) =>
        (TagSortingMap[a.name.toLowerCase().trim()] || Infinity) -
        (TagSortingMap[b.name.toLowerCase().trim()] || Infinity)
    )
  }
  return tags
}

export {
  mapFiltersFromUrl,
  modifyFormat,
  generateQueryString,
  mapFiltersFromUrlToCSV,
  convertFilters,
  tagFilterSorting,
  removeNullValues,
}
