/*
This doc maintain list name of all feature flags defined in split.io
Naming of feature in split should follow the structure `web_{Team Prefix}_featureName`
*/

const SPLIT_FEATURES = {
  //these are config, so please do not remove
  //begin config
  GST_VALUE: 'fe_gst_amount',
  BOYS_BRIGADE: 'fe_brow_boys_brigade',
  WEB_CONFIGURATIONS: 'web_configurations',
  SERVICE_FEES: 'fe_brow_service_fees',
  DC_CONFIG: 'fe_checkout_dcConfig',
  DC_FREE_TRIAL: 'fe_account_dc_free_trial',
  DC_FREE_TRIAL_BENEFITS: 'fe_dc_benefits',
  BUSINESS_PROFILE_INDUSTRIES: 'ef_business_profile_industries',
  WEB_INVOICE_CONFIGS: 'web_invoice_configs',
  EXTRA_LP_FLAG: 'isExtraLPInfoOn',
  SEC_LANDING_PAGE: 'fe_sec_campaign',
  VOUCHER_WALLET_SORTING: 'fe_voucher_wallet_sorting',
  APPLIED_VOUCHERS: 'fe_applied_vouchers_group',
  PAYMENT_SDK_APPLE_GOOGLE_PAY_ENABLED: 'fe_checkout_apple_google_pay',
  ELIGLIBLE_VOUCHER_SELECTION: 'fe_eligible_voucher_selection',
  PAYMENT_GATEWAY_DOWN: 'fe_pricing_payment_gateway_down',
  CARD_SIGN_DYNAMIC_FIELDS: 'fe_pricing_payment_cardsign_dynamic_fields', // use in the payment sdk
  TRUST_PLACEMENT_ENABLED: 'fe_pricing_payment_trust_placement_enabled', // use in the payment sdk
  GOOGLE_PAY_3DS: 'fe_pricing_payment_google_pay_3ds_only_web', // use in the payment sdk
  //end config
  //begin backend experiment
  BACKEND_EXP_KEY: 'web_brow_backend_driven_exp',
  //end ackend experiment
  SUBSTITUTION_SWITCH: 'web_checkout_substitution_switch', //grocery-cart
  SUBSTITUTION_DATA: 'fe_checkout_substitution_popup', //grocery-cart
  GIFT_ENTRY_POINT: 'fe_pay_gift_entry_point', //
  RATINGS: 'fe_brow_web_ratings', //grocery-discovery
  ES_ORDER_DURATION: 'fe_brow_es_order_duration', //grocery-discovery
  DF_CONFIGS: 'fe_checkout_df_config', //grocery-cart
  DF_SELLER_TIMESLOT: 'fe_checkout_df_isSellerTimeSlotEnabled', //grocery-cart
  DF_SELLER_TOOLTIP_CONFIG: 'fe_checkout_df_tool_tip_data', //grocery-cart
  POA_FIRST_MILESTONE: 'fe_poa_mst_1_enabled', //grocery-cart
  POA_FIRST_POINT_ONE_MILESTONE: 'fe_poa_mst_1_point_1_enabled', //grocery-cart
  LP_NEW_DISPLAY_LOGIC: 'fe_linkpoint_earning_display_logic_enabled', //grocery-cart
  CHECKOUT_VOUCHER_REMOVAL_BANNER: 'fe_checkout_voucher_removal_banner', //grocery-cart
  GMC_ENABLING: 'fe_checkout_gmc_isEnabled', //grocery-cart
  SMART_RECOMM: 'web_brow_smart_recom', //grocery-search
  SHELF_LIFE: 'fe_brow_shelf_life', //grocery-discovery
  DATASCIENCE_ENABLED: 'fe_brow_datascience', //grocery-discovery
  OOS_REDUCED_QUANTITY: 'fe_checkout_oos_isReducedQuantityEnabled', //grocery-cart
  DISABLING_LP_TOGGLE_AFTER_PLACING_ORDER:
    'mobile_pay_always_off_lp_redemption', //grocery-cart
  SAME_DAY_NEXT_DAY: 'fe_same_day_next_day_feature', //grocery-cart
  SAME_DAY_NEXT_DAY_NEW_LOGIC:
    'fe_sdnd_need_early_delivery_label_logic_enabled', //grocery-cart
  COMPLEMENTARY_SWIMLANE_ENABLED: 'fe_complementary_swimlane_enabled', //grocery-discovery
  CART_GST_LABEL: 'cart_gst_label', //grocery-cart
  PURCHASE_ORDER_AMENDMENT_HOME_ENABLED: 'lifestyle_home_poa', //grocery-discovery
  PURCHASE_ORDER_AMENDMENT_ENABLED: 'fe_poa_mst_1_enabled', //grocery-discovery
  PUBLITAS: 'fe_publitas', //grocery-discovery
  CART_FILLER: 'fe_gsr_cartfiller', //grocery-search
  PROMO_PRIORITY: 'fe_dis_promo_priority', //grocery-discovery
  CATEGORY_COLLECTION_NAVIGATION: 'fe_gsr_catnav', //grocery-search
  CAT_BUBBLE_ENABLED: 'fe_dis_catbubog', //grocery-discovery
  SWIMLANE_WITH_BANNER_ENABLED: 'dis_swimbanner', //grocery-discovery
  SWIMLANE_COUNTDOWN_ENABLED: 'fe_dis_countdown', //grocery-discovery
  PRODUCT_CAMPAIGN_LABEL_ENABLED: 'fe_dis_product_campaign_label', //grocery-discovery
  SWA_SWIMLANE_ENABLED: 'fe_brow_promobanner', //grocery-discovery
  ALGOLIA_PERSONALISATION: 'fe_gsr_algoliapersonalisation', //grocery-search
  HEALTHIER_CHOICE_INFO_ENABLED: 'fe_hpb_healthier_choice', //grocery-discovery
  CHECKOUT_DELIVERY_PREF_ENABLED: 'fe_delivery_preference_enabled', //grocery-cart
  CHECKOUT_DELIVERY_PREF_FFS: 'fe_delivery_preference_ffs', //grocery-cart
  CHECKOUT_DELIVERY_PREF_PFC: 'fe_delivery_preference_pfc', //grocery-cart
  CART_ALCOHOL_DISCLAIMER: 'fe_cart_alcohol_disclaimer', //grocery-cart
  CART_ALCOHOL_DISCLAIMER_CATEGORY_IDS: 'fe_cart_alcohol_disclaimer_ids', //grocery-cart
  FIX_EMPTY_SEARCHPAGE: 'fe_gsr_fix_empty_search_result_page', //grocery-search
  FE_SEGMENT_BRAND_MAIN_CLICKED: 'fe_seg_brand_main_clicked', //grocery-search
  FE_SEGMENT_PROMO_MAIN_CLICKED: 'fe_seg_promo_main_clicked', //grocery-search
  FE_SEGMENT_SEARCH_COMPLETED: 'fe_seg_search_completed', //grocery-search
  FE_SEGMENT_CATEGORY_MAIN_CLICKED: 'fe_seg_category_main_clicked', //grocery-search
  NO_SWITCH_PFC: 'fe_dis_no_switch_pfc', //grocery-discovery
  FE_DISABLE_ORIGINAL_STORE_BULK_THRESHOLD_CHECK:
    'fe_cart_disable_original_store_bulk_threshold_check', //grocery-cart
  FE_SEGMENT_ACCOUNT_CREATED: 'fe_seg_account_created', //grocery-search
  FE_SEGMENT_IDENTIFY_CALLS: 'fe_seg_identify_calls', //grocery-search
  FE_SEGMENT_APP_BROWSER_OPENED: 'fe_seg_appbrowser_opened', //grocery-search
  FOOD_WELLNESS_ENTRY_POINT: 'fe_dis_food_and_wellness_entry_point', //grocery-discovery
  FE_DY_HEADERS_INCLUDE: 'fe_dy_headers_include', //grocery-search
  FE_SEGMENT_OG_B2C_CHECKOUT_PLACE_ORDER_CLICKED:
    'fe_seg_og_b2c_checkout_place_order_clicked', //grocery-cart
  FE_SEGMENT_OG_B2B_CHECKOUT_PLACE_ORDER_CLICKED:
    'fe_seg_og_b2b_checkout_place_order_clicked', //grocery-cart
  FE_SEGMENT_OG_CHECKOUT_AMENDMENT_CLICKED: 'fe_seg_checkout_amendment_clicked', //grocery-cart
  FE_GSR_MIGRATION_WTYL: 'fe_gsr_migration_wtyl', //grocery-search
  FE_POA_DELETED_ADDRESS_HANDLING:
    'fe_checkout_poa_deleted_address_handling_enabled', //grocery-cart
  FE_GT_REC_WTYL: 'fe_gt_rec_wtyl', //grocery-search
  FE_GT_REC_FBT: 'fe_gt_rec_fbt', //grocery-search
  FE_GT_REC_CAT: 'fe_gt_rec_category', //grocery-search
  FE_GT_REC_PDBN: 'fe_gt_rec_pdbn', //grocery-search
  FE_GT_REC_WS: 'fe_gt_rec_ws', //grocery-search
  FE_GT_REC_R4U: 'fe_gt_rec_r4u', //grocery-search
  FE_OG_QUANTITY_INPUT_VALIDATION_FIX: 'fe_og_qty_input_validation_fix', //grocery-cart
  FE_DIS_PDP_VOUCHERS: 'fe_dis_pdp_vouchers', //grocery-discovery
  FE_DIS_MARKETING_LEAD_GEN: 'fe_dis_marketing_lead_gen', //grocery-discovery
  FE_CART_VOUCHER_WOBBLER: 'fe_cart_voucher_wobbler', // grocery-cart
  FE_ZERO_SEARCH_SWIMLANE_ENABLED: 'fe_zero_search_swimlane_enabled', //grocery-cart

  FE_POA_HIGHLIGHT_FLAG: 'fe_poa_highlight_flag', //fullfillment
  FF_HIDE_ORDER_FEEDBACK: 'ff_hide_order_feedback', //fullfillment
  FF_ORDER_AMENDMENT: 'ff_order_amendment', //fullfillment
  FE_DELIVERY_NOTES: 'fe_od_deliveryNotes_enabled', //fullfillment
  B2B_QUICKBUY: 'fe_quickbuy', //fullfillment
  FF_EARNING_SECTION_ENABLED: 'ff_earning_section_enabled', //fullfillment
  FE_ELIGIBLE_ITEM_ADD_TO_CART_ENABLED: 'fe_eligible_item_add_to_cart_enabled', //fullfillment
  FE_RETURN_VOUCHER_ENABLED: 'fe_return_voucher_enabled', //fullfillment
  FE_ENABLE_LOCAL_STORAGE_ORDER_RESPONSE:
    'fe_enable_local_storage_order_response', //fullfillment
  FE_CITRUS_TRACKING_V2: 'fe_citrus_tracking_v2',
}

module.exports = {
  SPLIT_FEATURES,
}
