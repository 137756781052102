import Router from 'next/router'
import App from 'next/app'
import React, { Fragment } from 'react'
import _get from 'lodash/get'
import dynamic from 'next/dynamic'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import getConfig from 'next/config'
import nookies from 'nookies'
const FullPageErrorLayout = dynamic(() =>
  import('../components/Error/FullPageErrorLayout')
)
import { GlobalLoader } from '../components/Loader/Loader'
import GlobalContext from './../components/GlobalContext'
import CartProvider, { store } from './../components/CartProvider'
import WishlistProvider from './../components/WishlistProvider'
import PromocodeProvider from '../components/PromocodeProvider'
import { IdleQueue } from '../lib/idlize/IdleQueue'
const queue = new IdleQueue()
import { pubSubEvent, PUB_SUB_EVENTS } from '../utils/pubSub'
import { get } from '../lib/fetch'
import {
  AccountProviderAuth0,
  AUTH_TOKEN_KEY,
} from '../components/AccountProvider'
import EventTrackingProvider from '../components/EventTrackingProvider/EventTrackingProvider'
import CheckoutAddressProvider, {
  CheckoutAddressConsumer,
} from '../components/CheckoutAddressProvider'
import { verifyPagePermission } from './../lib/redirect'
import {
  getDomainForCookies,
  authTokenCookiOptions,
} from '../utils/cookieUtils'
import RouteChangeTracking from './RouteChangeTracking'
import { setConfig } from '../utils/configService'
import AppRouteTracker from '../components/AppRouteTracker'
import { SCREEN_SIZE } from '../lib/Media'
import { setCtaLocationMeta, setPreviousCtaLocationMeta } from '../lib/utils'
import { datadogRum } from '@datadog/browser-rum-slim'
import PostOrderAmendProvider from '../components/PostOrdeAmendProvider/PostOrderAmendProvider'
import { AgeConsentProvider } from '../components/AgeConsent/AgeConsentProvider'
import WelcomeProfileUpdate from '../components/WelcomeProfileUpdate/WelcomeProfileUpdate'

const {
  publicRuntimeConfig: {
    API_URL,
    NODE_ENV,
    RELEASEID,
    ENVIRONMENT,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_RUM_CLIENT_TOKEN,
  },
} = getConfig()

// this will initialize datadog
if (
  typeof window !== 'undefined' &&
  (ENVIRONMENT === 'PROD' ||
    ENVIRONMENT === 'UAT' ||
    ENVIRONMENT === 'UATPREVIEW')
) {
  datadogRum.init({
    applicationId: DATADOG_RUM_APPLICATION_ID,
    clientToken: DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'fairprice-website',
    enableExperimentalFeatures: ['feature_flags'],
    env: ENVIRONMENT,
    version: RELEASEID,
    sampleRate: 20,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    telemetrySampleRate: 0,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    tracingSampleRate: 100,
    allowedTracingUrls: [
      'https://website-api.zs-uat.fairprice.com.sg',
      'https://storetech-uat.fairprice.com.sg',
      'https://website-api.omni.fairprice.com.sg',
      'https://storetech.fairprice.com.sg',
    ],
    beforeSend: event => {
      // discard a RUM error event
      if (event.type === 'error') {
        // discard a RUM error if its message includes 'Script error.'
        if (event.error.message.includes('Script error.')) {
          return false
        }

        let matchedBlackListedUrls = false
        let matchedBlackListedMessages = false
        //here we target error message
        const blackListedMessages = [
          'https://www.googletagmanager.com/gtm.js',
          'https://try.abtasty.com',
          'https://analytics.tiktok.com',
          'https://cdn.sprig.com',
          'Minified React error #418',
          'Minified React error #423',
          'Minified React error #425',
          'https://www.fairprice.com.sg/wp-content',
          'https://www.fairprice.com.sg/careers/wp-content',
        ]
        //here we target urls from where error message is coming
        const blackListedUrls = [
          'https://www.fairprice.com.sg/events',
          'https://www.fairprice.com.sg/store-weekly-ads',
          'https://www.fairprice.com.sg/tips',
          'https://www.fairprice.com.sg/promo/',

          // it's not ideal to mute RUM errors from localhost this way
          // one proper solution could be adding a predev hook
          // to check DATADOG_RUM_* .env var (should be empty for local)
          'http://localhost:3000',
          'http://fairprice.test:3000',
        ]
        blackListedUrls.forEach(blackListedUrl => {
          if (event?.view?.url.includes(blackListedUrl)) {
            matchedBlackListedUrls = true
          }
        })
        blackListedMessages.forEach(blackListedMessage => {
          const { stack } = event.error
          if (stack && stack.includes(blackListedMessage)) {
            matchedBlackListedMessages = true
          }
        })
        if (matchedBlackListedUrls || matchedBlackListedMessages) {
          return false
        }
      }
    },
  })
}

const GlobalStyle = createGlobalStyle`
  ${reset}
  * { box-sizing: border-box; }
  img { vertical-align: bottom; }
  html { background-color: #f6f6f6; -webkit-font-smoothing: antialiased; }
  button { font: inherit; }
  html, body, input {font-family: 'Lato', sans-serif;}

  ._ndsModal.recipe_product_view_options_modal {
    width: 32.625rem;
    max-height: calc(100vh - 6.25rem);
    min-height: calc(100vh - 6.25rem);
    overflow-y: scroll;
    padding: 0;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /*To hide scrollbar in firefox */

    ${SCREEN_SIZE.Below.Mobile} {
      min-width: 100%;
      top: 0;
      left: 0;
      transform: none;
      min-height: 100vh;
    }
  }
  .hide-content {
    z-index: 0 !important;
  }
  .hide-content-position {
    position: relative !important;
    z-index: -1 !important;
  }
`
const FontStyles = createGlobalStyle`
@font-face{font-family:Lato;font-style:normal;font-weight:400;font-display:optional;src:local('Lato Regular'),local('Lato-Regular'),url(/static/fonts/latoRegular.woff2) format('woff2'),url(/static/fonts/latoRegular.woff) format('woff'),url(/static/fonts/latoRegular.ttf) format('truetype');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}@font-face{font-family:Lato;font-style:normal;font-weight:700;font-display:optional;src:local('Lato Bold'),local('Lato-Bold'),url(/static/fonts/latoBold.woff2) format('woff2'),url(/static/fonts/latoRegular.woff) format('woff'),url(/static/fonts/latoBold.ttf) format('truetype');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}@font-face{font-family:Lato;font-style:normal;font-weight:900;font-display:optional;src:local('Lato Black'),local('Lato-Black'),url(/static/fonts/latoBlack.woff2) format('woff2'),url(/static/fonts/latoRegular.woff) format('woff'),url(/static/fonts/latoBlack.ttf) format('truetype');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}
`

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const treatments = ctx.res
      ? _get(ctx, 'res.locals.treatments')
      : window.__NEXT_DATA__.props.treatments
    // SetTreatments in Window Object
    // Keep this call at the top,
    // so that treatments are set before accessing in server side requests
    if (treatments) {
      setConfig(treatments)
    }
    // When used with `useClientRouting`, this has the unfortunate effect of
    // showing the next page's skeletons _before_ getting a `401` and then redirecting
    // to the login page. If it is desired to not even show the next page when
    // there is a `401`, move the `/api/me` request out of Nodejs into this method so
    // next-page navigation is blocked until the user's authenticity can be verified.

    // Reading the remaote config data
    const user_type = ctx.res
      ? _get(ctx, 'res.locals.userType')
      : window.__NEXT_DATA__.props.userType
    const remoteConfig = ctx.res
      ? _get(ctx, 'res.locals.remoteConfigData')
      : window.__NEXT_DATA__.props.remoteConfig
    const userKey = ctx.res
      ? _get(ctx, 'res.locals.userKey')
      : window.__NEXT_DATA__.props.userKey
    const account = ctx.res
      ? _get(ctx, 'res.locals.account')
      : window.__NEXT_DATA__.props.account

    //server side :
    const isPreviewMode =
      ENVIRONMENT === 'UATPREVIEW' || ENVIRONMENT === 'PRODPREVIEW'
    const coookieDomain = isPreviewMode
      ? _get(ctx, 'req.hostname')
      : getDomainForCookies(_get(ctx, 'req.hostname'))
    const accessToken = _get(ctx, 'req.session.passport.user.accessToken')
    if (accessToken) {
      nookies.set(
        {},
        AUTH_TOKEN_KEY,
        accessToken,
        authTokenCookiOptions(coookieDomain)
      )
    }

    const { isAccessible, redirect } = verifyPagePermission(
      ctx,
      Component,
      // `account` is being used instead of cookie values because
      // when Express removes a cookie, it only takes effect on the next
      // request hence we can't count on express to delete cookie on the same
      // request.
      account
    )

    //if not login then clear cauth coookie and redirect
    if (!isAccessible) {
      const coookieDomain = getDomainForCookies(_get(ctx, 'req.hostname'))
      nookies.destroy({}, AUTH_TOKEN_KEY, authTokenCookiOptions(coookieDomain))

      redirect()
      return {}
    }

    let pageProps = {}

    if (Component.getInitialProps) {
      // When `getInitialProps` is not async (because of `supportClientRouting`),
      // this method will return immediately (thus won't block rendering).
      pageProps = await Component.getInitialProps(ctx)
    }

    const organization = ctx.res
      ? ctx.res.locals.organizationData
      : window.__NEXT_DATA__.props.organizationData

    const b2CCheckoutAddress = ctx.res
      ? ctx.res.locals.checkoutAddress
      : window.__NEXT_DATA__.props.checkoutAddress

    const b2bCheckoutAddress = ctx.res
      ? ctx.res.locals.b2bCheckoutAddress
      : window.__NEXT_DATA__.props.b2bCheckoutAddress

    const WHITE_LISTED_URLS = ['/vouchers/:slug', '/voucher/:slug']
    const isWhiteListedUrl = WHITE_LISTED_URLS.includes(ctx.req?.route?.path)

    const categories = ctx.res ? ctx.res.locals.navInfo : null
    return {
      pageProps,
      categories,
      organizationData: organization,
      account,
      b2bCheckoutAddress,
      checkoutAddress:
        user_type === 'B2B' ? b2bCheckoutAddress : b2CCheckoutAddress,
      remoteConfig,
      isWhiteListedUrl,
      treatments,
      userKey,
      user_type,
    }
  }

  constructor(props) {
    super(props)
    const { categories, treatments } = props
    // SetTreatments in Window Object
    if (treatments) {
      setConfig(treatments)
    }

    this.state = {
      loading: false,
      categories: { category: categories?.length ? categories[0] : [] },
      experiments: {
        cartRecommendVariant: '',
        homeRecommendVariant: '',
        searchRepurchaseVariant: 1,
      },
    }
    this.handleRouteChangeCompleted = this.handleRouteChangeCompleted.bind(this)
    this.handleRouteChangeStart = this.handleRouteChangeStart.bind(this)
    this.updateUnhandledError = this.updateUnhandledError.bind(this)
    this.getNavInfo = this.getNavInfo.bind(this)
  }

  handleRouteChangeStart(url) {
    // store current as previous cta location meta (for 2click cta location)
    setPreviousCtaLocationMeta()
    // reset cta location meta
    setCtaLocationMeta([])
    // Prevent Spinner loader in PDP pages and accounts page
    if (url && !url.includes('/product/') && !url.includes('/accounts?')) {
      this.setState({
        loading: true,
        isUnhandledError: false,
      })
    }

    // to persist existing behaviour to make isUnhandledError false on PDP page as well
    if (url && url.includes('/product/')) {
      this.setState({
        isUnhandledError: false,
      })
    }
  }

  handleRouteChangeCompleted() {
    this.setState({
      loading: false,
    })
    window.scrollTo(0, 0)
  }

  async getNavInfo(checkoutAddress) {
    const storeId = checkoutAddress
      ? checkoutAddress.storeId
      : this.props.organizationData.defaultStoreId
    const { data } = await get(`nav?storeId=${storeId}`)
    this.setState({
      categories: {
        category: data?.length ? data[0] : [],
      },
    })
  }

  async componentDidMount() {
    Router.events.on('routeChangeStart', this.handleRouteChangeStart)
    Router.events.on('routeChangeError', this.handleRouteChangeCompleted)
    Router.router.events.on('routeChangeComplete', () => {
      this.handleRouteChangeCompleted()
    })
    if (!this.state.categories) {
      queue.pushTask(() => {
        this.getNavInfo(this.props.checkoutAddress)
      })
    }
    pubSubEvent.subscribe(PUB_SUB_EVENTS.ADDRESS_CHANGED, address => {
      this.getNavInfo(address)
    })
  }

  componentWillUnMount() {
    Router.events.off('routeChangeStart', this.handleRouteChangeStart)
    Router.events.off('routeChangeComplete', this.handleRouteChangeCompleted)
    Router.events.off('routeChangeError', this.handleRouteChangeCompleted)
  }

  componentDidCatch(error, info) {
    this.setState({
      isUnhandledError: true,
    })
    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = info.componentStack
    renderingError.cause = error
    datadogRum.addError(renderingError, {
      errorType: 'Internal',
    })
  }

  updateUnhandledError() {
    this.setState({
      isUnhandledError: false,
    })
  }

  render() {
    const {
      Component,
      pageProps,
      organizationData,
      account,
      checkoutAddress,
      remoteConfig,
      isWhiteListedUrl,
      userKey,
      user_type,
    } = this.props
    const { experiments } = this.state
    const multiVariantExperiments = []
    const AccountProvider = AccountProviderAuth0
    const { isUnhandledError, categories } = this.state

    const renderComponent = () => {
      if (isWhiteListedUrl) {
        return (
          <EventTrackingProvider userKey={userKey}>
            <RouteChangeTracking />
            <Component
              {...pageProps}
              organizationData={organizationData}
              categoryList={categories}
              checkoutAddress={checkoutAddress}
            />
          </EventTrackingProvider>
        )
      }
      return (
        <AccountProvider defaultAccountData={account}>
          <CheckoutAddressProvider
            defaultAddress={checkoutAddress}
            organizationData={organizationData}
          >
            <EventTrackingProvider userKey={userKey}>
              <PostOrderAmendProvider>
                <CartProvider
                  defaultItems={store(user_type !== 'B2B' ? 'cart' : 'b2bCart')}
                  organizationData={organizationData}
                >
                  <AgeConsentProvider>
                    <WishlistProvider defaultItems={store('wishlist')}>
                      <PromocodeProvider>
                        <RouteChangeTracking />
                        <CheckoutAddressConsumer>
                          {({ checkoutAddress }) => (
                            <Component
                              {...pageProps}
                              organizationData={organizationData}
                              categoryList={categories}
                              checkoutAddress={checkoutAddress}
                            />
                          )}
                        </CheckoutAddressConsumer>
                      </PromocodeProvider>
                    </WishlistProvider>
                  </AgeConsentProvider>
                </CartProvider>
              </PostOrderAmendProvider>
              <WelcomeProfileUpdate />
            </EventTrackingProvider>
          </CheckoutAddressProvider>
        </AccountProvider>
      )
    }

    return (
      <Fragment>
        <GlobalStyle />
        {NODE_ENV === 'production' && <FontStyles />}
        {this.state.loading && <GlobalLoader />}
        {isUnhandledError ? (
          <FullPageErrorLayout
            updateUnhandledError={this.updateUnhandledError}
          />
        ) : (
          <GlobalContext.Provider
            value={{
              API_URL,
              asPath: this.props.router.asPath,
              remoteConfig: remoteConfig,
              experiments: experiments,
              organizationData: organizationData,
              multiVariantExperiments: multiVariantExperiments,
            }}
          >
            {renderComponent()}
            <AppRouteTracker />
          </GlobalContext.Provider>
        )}
      </Fragment>
    )
  }
}

export default MyApp
