import React, { Component, createContext } from 'react'
import format from 'date-fns/format'
import getConfig from 'next/config'
import mapKeys from 'lodash/mapKeys'
import queryString from 'query-string'
import { getAllConfig } from '../../utils/configService'
import { AccountConsumer } from '../AccountProvider'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider'
import GlobalContext from '../GlobalContext'
import {
  GTM_ENV_VAR as GEV,
  hexSHA256String,
  getNCheckFn,
  SORT_DEFAULT_VALUES,
  FILTER_DIMENSIONS,
} from '../../lib/eventTrackerFunction'
import { mapFiltersFromUrl } from '../../lib/filterFormatter'
import { SPLIT_EXCLUDE_LIST } from '../../lib/splitImpressionsTracker'
import {
  pushDataLayerToAmplitude,
  sendGaEventToAmplitude,
} from '../../lib/amplitude'
import { getUserType } from '../../utils/userType'
import { datadogRum } from '@datadog/browser-rum-slim'
const {
  publicRuntimeConfig: {
    ENVIRONMENT,
    RELEASEID,
    SPLIT_CLIENT_TOKEN,
    AMPLITUDE_TOKEN,
  },
} = getConfig()

/* Creating contex for event tracking. It will provide with a function named as track.
  Track: This function can be used to send the events and it will append all other values as
  undefined for dataLayer.
*/
const EventTrackingContext = createContext({
  track: () => {},
  trackSplit: () => {},
  getTestVersions: () => {},
})

const getNCheck = getNCheckFn

const { Provider, Consumer } = EventTrackingContext

const _getAccountType = accData => {
  const isDcMbr = getNCheck(accData, 'memberships.fairprice_digital_club', null)
  const isJwcMbr = getNCheck(accData, 'memberships.just_wine_club', null)
  const isPlusMbr = getNCheck(accData, 'association.linkplus', null)
  const _accType = [
    ...(isDcMbr ? ['Digital Club'] : []),
    ...(isJwcMbr ? ['JWC'] : []),
    ...(isPlusMbr ? ['Plus'] : []),
  ]
  return _accType.join(',')
}
class EventTrackingProvider extends Component {
  constructor(props) {
    super(props)

    this.track = this.track.bind(this)
    this.trackSplit = this.trackSplit.bind(this)
    this.extractFilters = this.extractFilters.bind(this)
    this.extractQueryParams = this.extractQueryParams.bind(this)
    this.getTestVersions = this.getTestVersions.bind(this)
    this.getExperimentResults = this.getExperimentResults.bind(this)
    this.initAmplitude = this.initAmplitude.bind(this)
    this.extractSuggestionImpressions =
      this.extractSuggestionImpressions.bind(this)
    this.clean = this.clean.bind(this)
    this.init = this.init.bind(this)
    this.initSplitTracking = this.initSplitTracking.bind(this)
    this.state = {
      track: props.track || this.track,
      trackSplit: props.trackSplit || this.trackSplit,
      getTestVersions: this.getTestVersions,
      splitClient: undefined,
      amplitude: null,
      amplitudeSessionId: null,
    }
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      this.init()
    } else {
      window.addEventListener('load', this.init)
    }
  }

  initSplitTracking() {
    const that = this
    const { userKey } = this.props
    if (userKey) {
      import('@splitsoftware/splitio').then(module => {
        const factory = module.SplitFactory({
          core: {
            authorizationKey: SPLIT_CLIENT_TOKEN,
            key: userKey,
            trafficType: 'user',
          },
          impressionListener: {
            logImpression(impressionData) {
              datadogRum.addFeatureFlagEvaluation(
                impressionData.impression.feature,
                impressionData.impression.treatment
              )
            },
          },
        })
        const client = factory.client()
        window.dataLayer.forEach(gtEvent => {
          if (
            gtEvent.environment &&
            !SPLIT_EXCLUDE_LIST.includes(gtEvent.event)
          ) {
            const modifiedEvent = gtEvent.event
              ? gtEvent.event
              : 'hm_push_event'
            client.track(modifiedEvent, null, that.clean(gtEvent))
          }
        })
        that.setState({ splitClient: client })
      })
    }
  }

  init() {
    this.initAmplitude()
    this.initSplitTracking()
  }
  initAmplitude() {
    if (this.amplitude) {
      return
    }
    const { isLoggedIn, accountData } = this.props
    let userid = ''
    if (isLoggedIn && accountData?.id) {
      userid = accountData.id
    }
    import('amplitude-js').then(amplitude => {
      if (this.amplitude) {
        return
      }
      this.amplitude = amplitude
      window.fp_amplitude = amplitude
      /* istanbul ignore next */
      amplitude.default.getInstance().init(AMPLITUDE_TOKEN, userid, {
        apiEndpoint:
          ENVIRONMENT === 'PROD' || ENVIRONMENT === 'PRODPREVIEW'
            ? 'website-api.omni.fairprice.com.sg/api/tracking/events'
            : 'website-api.zs-uat.fairprice.com.sg/api/tracking/events',
      })
      this.setState({
        amplitudeSessionId: amplitude.default
          ?.getInstance?.()
          ?.getSessionId?.(),
      })
      pushDataLayerToAmplitude(this.amplitude, this.props.userKey)
    })
  }

  // returns value of given param
  extractQueryParams(paramToExtract) {
    const windowLocation = window.location
    const parsedQueryParams = queryString.parse(windowLocation.search)
    const extractedParam = parsedQueryParams[[paramToExtract]]

    return extractedParam
  }

  // returns selected filters
  extractFilters() {
    const filterTrackingData = {}
    // setting all filters to undefined initially
    // by default need to send all keys
    Object.keys(FILTER_DIMENSIONS).forEach(filterDimention => {
      filterTrackingData[FILTER_DIMENSIONS[[filterDimention]]] = undefined
    })

    const filtersFromQuery = this.extractQueryParams('filter')
    let filters = filtersFromQuery ? mapFiltersFromUrl(filtersFromQuery) : []
    filters = Object.keys(filters).length
      ? mapKeys(filters, function (value, key) {
          return `FIL_ITRC_${key.split(' ').join('_').toUpperCase()}`
        })
      : []

    Object.keys(filters).forEach(filterKey => {
      if (GEV[[filterKey]]) {
        filterTrackingData[GEV[[filterKey]]] = filters[filterKey]
          .sort()
          .join(',')
      } else {
        // this condition is required intrack case new filter is introduced
        // the corresponsing dimension key won't be available for the same in GEV
        filterTrackingData['filter_interaction_' + [[filterKey]]] = filters[
          filterKey
        ]
          .sort()
          .join(',')
      }
    })

    return filterTrackingData
  }

  /**
   * @param {*} testing_version
   * Reads experimentTrackingList from remote config
   * checks variant for experiments & multiVariantExperiments set in global context
   * combines all values comma separated
   * being sent in tracking as GEV.TESTING_VERSION
   * being sent to API calls
   */
  getTestVersions(testing_version) {
    const { experiments, remoteConfig, multiVariantExperiments } = this.props
    const allExp = getAllConfig() || {}
    let experimentResults = testing_version ? testing_version : ''
    if (remoteConfig) {
      const experimentTrackingList = [
        'homeRecommendVariant',
        'storeSelectorVariant',
        'cartRecommendVariant',
      ]
      experimentResults = this.getExperimentResults(
        experiments,
        experimentTrackingList,
        multiVariantExperiments,
        experimentResults,
        false
      )
      experimentResults = this.getExperimentResults(
        {},
        experimentTrackingList,
        multiVariantExperiments,
        experimentResults,
        false
      )
    }
    experimentResults = this.getExperimentResults(
      allExp,
      [],
      [],
      experimentResults,
      true
    )
    return experimentResults
  }

  getExperimentResults(
    experiments,
    experimentTrackingList,
    multiVariantExperiments,
    experimentResults,
    isSplitObj = false
  ) {
    let expResult = ''
    const keys = Object.keys(experiments)
    if (isSplitObj) {
      keys.forEach(key => {
        const expConfig = experiments[key].config
          ? JSON.parse(experiments[key].config)
          : {}
        if (expConfig?.variantKey) {
          experimentResults =
            experimentResults !== ''
              ? `${experimentResults},${expConfig?.variantKey}`
              : expConfig?.variantKey
        }
      })
    } else {
      keys.forEach(key => {
        if (experimentTrackingList.indexOf(key) !== -1) {
          if (multiVariantExperiments.includes(key)) {
            if (experiments[key] > 0) {
              expResult = experiments[key] > 1 ? `${key}-B` : `${key}-A`
            }
          } else {
            expResult =
              experiments[key] === 1
                ? `${key}-B`
                : experiments[key] === 0
                ? `${key}-A`
                : ''
          }
          if (expResult !== '') {
            experimentResults =
              experimentResults !== ''
                ? `${experimentResults},${expResult}`
                : expResult
          }
        }
      })
    }
    return experimentResults
  }

  extractSuggestionImpressions(pushEvent) {
    const { event_action } = pushEvent
    const isValidEvent =
      event_action &&
      ['searchstart', 'searchcomplete', 'searchabandon'].includes(
        event_action.toLowerCase()
      )

    const regex = /^suggested_.*_impression$/g
    let suggestionImpressionData = {}
    Object.keys(pushEvent).forEach(key => {
      if (regex.test(key)) {
        if (isValidEvent) {
          suggestionImpressionData = {
            ...suggestionImpressionData,
            [key]: pushEvent[key],
          }
        } else {
          suggestionImpressionData = {
            ...suggestionImpressionData,
            [key]: '',
          }
        }
      }
    })
    return suggestionImpressionData
  }

  clean(dataObj) {
    const obj = JSON.parse(JSON.stringify(dataObj))
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
    return obj
  }

  trackSplit(splitEventPayload = []) {
    if (this.state.splitClient && splitEventPayload.length) {
      splitEventPayload.forEach(payload => {
        this.state.splitClient.track(payload?.event, null, {
          [GEV.ENV]: ENVIRONMENT,
          ...payload,
        })
      })
    }
  }

  track(pushEvent) {
    const { accountData, isLoggedIn, checkoutAddress } = this.props
    const suggestionImpression = this.extractSuggestionImpressions(pushEvent)

    let {
      product_id,
      product_name,
      product_category,
      product_category_l1,
      product_category_l2,
      product_category_l3,
      product_category_l4,
      product_currency,
      product_quantity,
      member_type,
      user_type,
      store_id,
      storage_instructions,
      search_term,
      product_brand,
      retail_price,
      offer_price,
      promo_text,
      product_catalog,
      inventory_count,
      page_type,
      search_result_count,
      first_order_date,
      membership_type,
      fulfilment_centre,
      amplitude_product_fulfilment_type,
      payment_type,
      promo_code,
      adblocker_status,
      transaction_number,
      asset_type,
      seller_name,
      event,
      content_type,
      content_id,
      event_category,
      event_action,
      event_label,
      transactionAffiliation,
      sku,
      price,
      quantity,
      name,
      seller,
      category,
      transactionTotal,
      transactionTax,
      transactionShipping,
      currency,
      transactionProducts,
      transactionId,
      url,
      keyword_selected,
      search_position,
      ecommerce,
      suggested_keyword_impression,
      suggested_seller_impression,
      suggested_campaign_impression,
      suggested_brand_impression,
      suggested_search_impression,
      suggested_recent_impression,
      cart_id_zop,
      product_id_zop,
      cart_id_wcs,
      cart_gross_value,
      cart_net_value,
      cart_detail,
      dimension61,
      dimension115,
      product_position,
      category_id,
      category_name,
      brand_id,
      brand_name,
      testing_version,
      listing_catalog,
      inventory_status,
      selling_price,
      jwc_id,
      screen_name,
      jwc_membership,
      age_above_18,
      jwc_consentpromotion,
      opt_membershipcard,
      referrer,
      auth_signup_entry_source,
      applied_promo,
      unapplied_promo,
      promo_type,
      dimension89,
      dimension90,
      dimension91,
      dimension97,
      dimension101,
      dimension102,
      dimension106,
      algolia_exp,
      state: stateVar,
      device_id,
      cart_product_checked,
      cart_product_unchecked,
      cartinstocklabel,
      recipe_id,
      recipe_title,
      amplitude_event_name,
      product_rating,
      promotion_count,
      promotion_types,
      promotion_descriptions,
      voucher_code,
      voucher_type,
      sap_store_id,
      number_of_vouchers_available,
      number_of_vouchers_applied,
      amplitude_cta_sublocation,
      amplitude_cta_location,
      amplitude_product_quantity,
      amplitude_promo_code,
      amplitude_total_cart_value,
      amplitude_delivery_timeslot,
      amplitude_redeem_linkpoint_option,
      amplitude_days_to_available_slots,
      amplitude_hours_to_available_slots,
      amplitude_days_to_selected_slot,
      amplitude_hours_to_selected_slot,
      amplitude_slots_availability_status,
      amplitude_category_name,
      amplitude_category_level,
      amplitude_login_user_type,
      amplitude_vendor_id,
      amplitude_vendor_name,
      amplitude_sort_by_category,
      amplitude_filter_category,
      amplitude_filter_details,
      amplitude_2click_call_to_action_location,
      amplitude_voucher_code,
      amplitude_voucher_type,
      amplitude_number_of_days,
      amplitude_message,
      amplitude_product_names,
      amplitude_product_skus,
      amplitude_product_quantities,
      amplitude_search_feature_selected,
      amplitude_final_order_value,
      amplitude_final_payment_value,
      amplitude_payment_option,
      amplitude_service_fee,
      amplitude_linkpoint_redemption,
      amplitude_delivery_fee,
      order_promotion_amount,
      match_type,
      amplitude_cta_sublocation_section_type,
      amplitude_recipe_title,
      amplitude_recipe_id,
      amplitude_recipe_category,
      amplitude_recipe_preparation_time,
      amplitude_recipe_cooking_time,
      amplitude_recipe_cooking_level,
      amplitude_recipe_serving_size,
      amplitude_recipe_number_of_ingredients,
      amplitude_recipe_number_of_steps,
      amplitude_recipe_media_type,
      amplitude_recipe_average_rating,
      amplitude_recipe_rating_count,
      amplitude_recipe_video_duration,
      amplitude_recipe_step_number,
      amplitude_percentage_watched,
      amplitude_recipe_review_rating,
      amplitude_recipe_review_display_name,
      amplitude_recipe_review_title,
      amplitude_recipe_review_text,
      amplitude_recipe_review_recommended_attribute,
      amplitude_recipe_review_easy_to_follow_attribute,
      amplitude_search_positions,
      amplitude_item_positions,
      amplitude_user_typed_search_term,
      search_features_count,
      amplitude_search_query_id,
      amplitude_search_index_name,
      amplitude_event_type,
      amplitude_event_scope,
      amplitude_filters,
      amplitude_number_of_active_orders,
      amplitude_delivery_order_id,
      amplitude_sales_order_id,
      amplitude_amendable_order,
      amplitude_cancellable_order,
      amplitude_order_amount,
      amplitude_store_id,
      amplitude_order_quantity,
      amplitude_hours_to_delivery_timeslot,
      amplitude_days_to_delivery_timeslot,
      amplitude_delivery_order_status,
      amplitude_sales_order_status,
      amplitude_order_type,
      total_product_count,
      amplitude_search_term_selected,
      amplitude_available_slot_time,
      amplitude_timeslot_current_time,
      amplitude_is_past_purchase,
      promotion_id,
      promotion_name,
      creative_name,
      creative_slot,
      items,
      amplitude_product_hours_to_available_slot,
      amplitude_product_days_to_available_slot,
      amplitude_mkp_item,
      amplitude_potential_timeslot_shown,
      amplitude_potential_hours_to_available_slot,
      amplitude_potential_days_to_available_slot,
      amplitude_order_promotion_type,
      amplitude_order_promotion_description,
      amplitude_fulfilment_type_in_array,
      amplitude_earlier_delivery_option,
      amplitude_leave_item_at_doorstep_option,
      amplitude_selected_delivery_instruction,
      segment_store_type,
      segment_screen_category,
      segment_substitution_option,
      segment_early_delivery_option,
      segment_leave_at_doorstep_option,
      segment_total_ordered_quantity,
      segment_delivery,
      segment_products,
      segment_order_offer,
      segment_distance_to_outlet,
      is_segment_event,
      snippet_version,
      screenName,
      ga4_suggested_campaign_impression,
      ga4_suggested_keyword_impression,
      ga4_suggested_search_impression,
      ga4_suggested_recent_impression,
      ga4_suggested_refinement_impression,
      banner_id,
      banner_name,
      banner_position,
      banner_type,
      store_type,
      destination_link,
      screen_category,
      vertical,
      call_to_action_location,
      call_to_action_sublocation,
      dy_event_properties,
      dy_engagement_properties,
      destinations,
      dyid,
      dysession,
      orderType,
      sdt,
      is_lower_case_logged_in_status,
      custom_checkout_step,
      track,
      is_voucher_available,
      is_voucher_shown,
      offer_id,
      message,
    } = pushEvent

    const userType = getUserType() || 'B2C'
    member_type = member_type || userType

    const filterTrackingData = this.extractFilters()
    const sortType = this.extractQueryParams('sorting')
    const experimentResults = this.getTestVersions(testing_version)
    const modifiedEvent = event ? event : 'hm_push_event'

    const GTEvent = {
      [GEV.ENV]: ENVIRONMENT,
      [GEV.RELEASE_VERSION]: RELEASEID,
      [GEV.ECOMMERCE]: ecommerce,
      [GEV.PROD_ID]: product_id,
      [GEV.STORE_ID]: store_id,
      [GEV.PROD_NAME]: product_name,
      [GEV.PROD_CAT_L]: product_category,
      [GEV.PROD_CAT_L1]: product_category_l1,
      [GEV.PROD_CAT_L2]: product_category_l2,
      [GEV.PROD_CAT_L3]: product_category_l3,
      [GEV.PROD_CAT_L4]: product_category_l4,
      [GEV.PROD_CUR]: product_currency,
      [GEV.PROD_QUANT]: product_quantity,
      [GEV.SORT_FILTER]:
        sortType && !!SORT_DEFAULT_VALUES.find(s => s.slug === sortType)
          ? SORT_DEFAULT_VALUES.find(s => s.slug === sortType).displayName
          : SORT_DEFAULT_VALUES.find(s => s.slug === 'RELEVANCE').displayName,
      [GEV.MEMBER_TYPE]: member_type,
      [GEV.LOGIN_STATUS]: isLoggedIn ? 'Yes' : 'No',
      [GEV.USER_TYPE]: user_type,
      [GEV.SEARCH_TERM]: search_term
        ? search_term
        : this.extractQueryParams('query'),
      [GEV.PROD_BRAND]: product_brand,
      [GEV.RETAIL_PRICE]: retail_price,
      [GEV.OFFER_PRICE]: offer_price,
      [GEV.PROMO_TEXT]: promo_text,
      [GEV.PROD_CAT]: product_catalog,
      [GEV.INVTRY_COUNT]: inventory_count,
      [GEV.PAGE_TYPE]: page_type,
      [GEV.SEARCH_RES_COUNT]: search_result_count,
      [GEV.FIR_ORDER_DATE]: first_order_date,
      [GEV.MEMSH_TYPE]: membership_type,
      [GEV.FUL_CENTER]: fulfilment_centre || checkoutAddress?.storeType,
      [GEV.PAY_TYPE]: payment_type,
      [GEV.PROMO_CODE]: promo_code,
      [GEV.AD_BLOCKER_STATUS]: adblocker_status,
      [GEV.TRANC_NO]: transaction_number,
      [GEV.ASSET_TYPE]: asset_type ? asset_type : 'FPON',
      [GEV.SELL_NAME]: seller_name ? seller_name : '',
      [GEV.USER_AGENT]: getNCheck(navigator, 'userAgent'),
      [GEV.EVENT]: modifiedEvent,
      [GEV.CONTENT_TYPE]: content_type,
      [GEV.CONTENT_ID]: content_id,
      [GEV.EVENT_CAT]: event_category,
      [GEV.EVENT_ACTION]: event_action,
      [GEV.EVENT_LABEL]: event_label,
      [GEV.STORAGE]: storage_instructions,
      [GEV.TRANSC_AFFIL]: transactionAffiliation,
      [GEV.SKU]: sku,
      [GEV.PRICE]: price,
      [GEV.QUANTITY]: quantity,
      [GEV.NAME]: name,
      [GEV.SELLER]: seller,
      [GEV.CATEGORY]: category,
      [GEV.TRANS_TOTAL]: transactionTotal,
      [GEV.TRANS_TAX]: transactionTax,
      [GEV.TRANS_SHIPPING]: transactionShipping,
      [GEV.CURRENCY]: currency,
      [GEV.TRANS_PRODUCTS]: transactionProducts,
      [GEV.TRANSC_ID]: transactionId,
      [GEV.KEYWORD_SELECTED]: keyword_selected,
      [GEV.SEARCH_POSITION]: search_position,
      [GEV.ECOMMERCE]: ecommerce,
      [GEV.SUGGESTED_KEYWORD_IMPRESSION]: suggested_keyword_impression,
      [GEV.SUGGESTED_SELLER_IMPRESSION]: suggested_seller_impression,
      [GEV.SUGGESTED_CAMPAIGN_IMPRESSION]: suggested_campaign_impression,
      [GEV.SUGGESTED_BRAND_IMPRESSION]: suggested_brand_impression,
      [GEV.SUGGESTED_SEARCH_IMPRESSION]: suggested_search_impression,
      [GEV.SUGGESTED_RECENT_IMPRESSION]: suggested_recent_impression,
      [GEV.GA4_SUGGESTED_CAMPAIGN_IMPRESSION]:
        ga4_suggested_campaign_impression,
      [GEV.GA4_SUGGESTED_KEYWORD_IMPRESSION]: ga4_suggested_keyword_impression,
      [GEV.GA4_SUGGESTED_SEARCH_IMPRESSION]: ga4_suggested_search_impression,
      [GEV.GA4_SUGGESTED_RECENT_IMPRESSION]: ga4_suggested_recent_impression,
      [GEV.GA4_SUGGESTED_REFINEMENT_IMPRESSION]:
        ga4_suggested_refinement_impression,
      [GEV.CUSTOM_CHECKOUT_STEP]: custom_checkout_step,
      [GEV.CART_ID_ZOP]: cart_id_zop,
      [GEV.PRODUCT_ID_ZOP]: product_id_zop,
      [GEV.CART_ID_WCS]: cart_id_wcs,
      [GEV.CART_GROSS_VALUE]: cart_gross_value,
      [GEV.CART_NET_VALUE]: cart_net_value,
      [GEV.CART_DETAIL]: cart_detail,
      [GEV.DIMENSION61]: dimension61,
      [GEV.ECOMM_IS_PAST_PURCHASE]: dimension115,
      [GEV.PRODUCT_POSITION]: product_position,
      [GEV.CATEGORY_ID]: category_id,
      [GEV.CATEGORY_NAME]: category_name,
      [GEV.BRAND_ID]: brand_id,
      [GEV.BRAND_NAME]: brand_name,
      [GEV.TESTING_VERSION]: experimentResults ? experimentResults : '',
      [GEV.INVENTORY_STATUS]: inventory_status,
      [GEV.ECOMM_SELLING_PRICE]: selling_price,
      [GEV.JWC_ID]: jwc_id,
      [GEV.SCREEN_NAME]: screen_name,
      [GEV.JWC_MEMBERSHIP]: jwc_membership,
      [GEV.AGE_ABOVE_18]: age_above_18,
      [GEV.JWC_CONSENTPROMOTION]: jwc_consentpromotion,
      [GEV.JWC_OPT_MEMBERSHIP_CARD]: opt_membershipcard,
      [GEV.JWC_REFERRER]: referrer,
      [GEV.SLUG]: window.location?.pathname,
      [GEV.AUTH_SIGNUP_ENTRY_SOURCE]: auth_signup_entry_source,
      [GEV.APPLIED_PROMO]: applied_promo,
      [GEV.UNAPPLIED_PROMO]: unapplied_promo,
      [GEV.PROMO_TYPE]: promo_type,
      [GEV.DIMENSION89]: dimension89,
      [GEV.DIMENSION90]: dimension90,
      [GEV.DIMENSION91]: dimension91,
      [GEV.DIMENSION97]: dimension97,
      [GEV.DIMENSION101]: dimension101,
      [GEV.DIMENSION102]: dimension102,
      [GEV.DIMENSION106]: dimension106,
      [GEV.ALGOLIA_EXP]: algolia_exp,
      [GEV.STATE]: stateVar,
      [GEV.DEVICE_ID]: device_id,
      [GEV.RECIPE_ID]: recipe_id,
      [GEV.RECIPE_TITLE]: recipe_title,
      [GEV.PRODUCT_RATING]: product_rating,
      [GEV.PROMOTION_COUNT]: promotion_count,
      [GEV.PROMOTION_TYPES]: promotion_types,
      [GEV.PROMOTION_DESCRIPTIONS]: promotion_descriptions,
      [GEV.MATCH_TYPE]: match_type,
      [GEV.SEARCH_FEATURES_COUNT]: search_features_count,
      [GEV.VOUCHER_CODE_VOUCHER_CODE]: voucher_code,
      [GEV.VOUCHER_CODE_VOUCHER_TYPE]: voucher_type,
      [GEV.VOUCHER_CODE_NO_OF_VOUCHERS]: number_of_vouchers_available,
      [GEV.VOUCHER_CODE_NO_OF_VOUCHERS_APPLIED]: number_of_vouchers_applied,
      [GEV.SAP_STORE_ID]: sap_store_id,
      [GEV.CREATIVE_NAME]: creative_name,
      [GEV.CREATIVE_SLOT]: creative_slot,
      [GEV.PROMOTION_ID]: promotion_id,
      [GEV.ITEMS]: items,
      [GEV.PROMOTION_NAME]: promotion_name,
      [GEV.SNIPPET_VERSION]: snippet_version,
      [GEV.SCREEN_NAME_GA4]: screenName,
      [GEV.AMPLITUDE_PRODUCT_HOURS_TO_AVAILABLE_SLOT]:
        amplitude_product_hours_to_available_slot,
      [GEV.AMPLITUDE_PRODUCT_DAYS_TO_AVAILABLE_SLOT]:
        amplitude_product_days_to_available_slot,
      [GEV.AMPLITUDE_IS_MKP_ITEMS]: amplitude_mkp_item,
      [GEV.AMPLITUDE_POTENTIAL_TIMESLOT_SHOWN]:
        amplitude_potential_timeslot_shown,
      [GEV.AMPLITUDE_POTENTIAL_HOURS_TO_AVAILABLE_SLOT]:
        amplitude_potential_hours_to_available_slot,
      [GEV.AMPLITUDE_POTENTIAL_DAYS_TO_AVAILABLE_SLOT]:
        amplitude_potential_days_to_available_slot,
      [GEV.AMPLITUDE_PRODUCT_FULFILMENT_TYPE]:
        amplitude_product_fulfilment_type,
      [GEV.AMPLITUDE_ORDER_PROMOTION_TYPE]: amplitude_order_promotion_type,
      [GEV.AMPLITUDE_ORDER_PROMOTION_DESCRIPTION]:
        amplitude_order_promotion_description,
      ...{
        [GEV.AMPLITUDE_EVENT_NAME]: amplitude_event_name,
        [GEV.AMPLITUDE_CTA_SUBLOCATION]: amplitude_cta_sublocation,
        [GEV.AMPLITUDE_CTA_LOCATION]: amplitude_cta_location,
        [GEV.AMPLITUDE_PRODUCT_QUANTITY]: amplitude_product_quantity,
        [GEV.AMPLITUDE_PROMO_CODE]: amplitude_promo_code,
        [GEV.AMPLITUDE_TOTAL_CART_VALUE]: amplitude_total_cart_value,
        [GEV.AMPLITUDE_DELIVERY_TIMESLOT]: amplitude_delivery_timeslot,
        [GEV.AMPLITUDE_REDEEM_LINKPOINT_OPTION]:
          amplitude_redeem_linkpoint_option,
        [GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS]:
          amplitude_days_to_available_slots,
        [GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS]:
          amplitude_hours_to_available_slots,
        [GEV.AMPLITUDE_DAYS_TO_SELECTED_SLOT]: amplitude_days_to_selected_slot,
        [GEV.AMPLITUDE_HOURS_TO_SELECTED_SLOT]:
          amplitude_hours_to_selected_slot,
        [GEV.AMPLITUDE_SLOTS_AVAILABILITY_STATUS]:
          amplitude_slots_availability_status,
        [GEV.AMPLITUDE_CATEGORY_NAME]: amplitude_category_name,
        [GEV.AMPLITUDE_CATEGORY_LEVEL]: amplitude_category_level,
        [GEV.AMPLITUDE_VENDOR_ID]: amplitude_vendor_id,
        [GEV.AMPLITUDE_VENDOR_NAME]: amplitude_vendor_name,
        [GEV.AMPLITUDE_SORT_BY_CATEGORY]: amplitude_sort_by_category,
        [GEV.AMPLITUDE_FILTER_CATEGORY]: amplitude_filter_category,
        [GEV.AMPLITUDE_FILTER_DETAILS]: amplitude_filter_details,
        [GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION]:
          amplitude_2click_call_to_action_location,
        [GEV.AMPLITUDE_VOUCHER_CODE]: amplitude_voucher_code,
        [GEV.AMPLITUDE_VOUCHER_TYPE]: amplitude_voucher_type,
        [GEV.AMPLITUDE_HANDLING_TIME_COUNT]: amplitude_number_of_days,
        [GEV.AMPLITUDE_MESSAGE]: amplitude_message,
        [GEV.AMPLITUDE_PRODUCT_NAMES]: amplitude_product_names,
        [GEV.AMPLITUDE_LIST_CATS]: amplitude_product_skus,
        [GEV.AMPLITUDE_PRODUCT_QUANTITIES]: amplitude_product_quantities,
        [GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED]:
          amplitude_search_feature_selected,
        [GEV.AMPLITUDE_FINAL_ORDER_VALUE]: amplitude_final_order_value,
        [GEV.AMPLITUDE_FINAL_PAYMENT_VALUE]: amplitude_final_payment_value,
        [GEV.AMPLITUDE_PAYMENT_OPTION]: amplitude_payment_option,
        [GEV.AMPLITUDE_DELIVERY_FEE]: amplitude_delivery_fee,
        [GEV.AMPLITUDE_SERVICE_FEE]: amplitude_service_fee,
        [GEV.AMPLITUDE_LINKPOINT_REDEMPTION]: amplitude_linkpoint_redemption,
        [GEV.AMPLITUDE_ORDER_PROMOTION_AMOUNT]: order_promotion_amount,
        [GEV.AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE]:
          amplitude_cta_sublocation_section_type,
        [GEV.AMPLITUDE_RECIPE_TITLE]: amplitude_recipe_title,
        [GEV.AMPLITUDE_RECIPE_ID]: amplitude_recipe_id,
        [GEV.AMPLITUDE_RECIPE_CATEGORY]: amplitude_recipe_category,
        [GEV.AMPLITUDE_RECIPE_PREPARATION_TIME]:
          amplitude_recipe_preparation_time,
        [GEV.AMPLITUDE_RECIPE_COOKING_TIME]: amplitude_recipe_cooking_time,
        [GEV.AMPLITUDE_RECIPE_COOKING_LEVEL]: amplitude_recipe_cooking_level,
        [GEV.AMPLITUDE_RECIPE_SERVING_SIZE]: amplitude_recipe_serving_size,
        [GEV.AMPLITUDE_RECIPE_NUMBER_OF_INGREDIENTS]:
          amplitude_recipe_number_of_ingredients,
        [GEV.AMPLITUDE_RECIPE_NUMBER_OF_STEPS]:
          amplitude_recipe_number_of_steps,
        [GEV.AMPLITUDE_RECIPE_MEDIA_TYPE]: amplitude_recipe_media_type,
        [GEV.AMPLITUDE_RECIPE_AVERAGE_RATING]: amplitude_recipe_average_rating,
        [GEV.AMPLITUDE_RECIPE_RATING_COUNT]: amplitude_recipe_rating_count,
        [GEV.AMPLITUDE_RECIPE_STEP_NUMBER]: amplitude_recipe_step_number,
        [GEV.AMPLITUDE_RECIPE_VIDEO_DURATION]: amplitude_recipe_video_duration,
        [GEV.AMPLITUDE_PERCENTAGE_WATCHED]: amplitude_percentage_watched,
        [GEV.AMPLITUDE_SEARCH_POSITIONS]: amplitude_search_positions,
        [GEV.AMPLITUDE_ITEM_POSITIONS]: amplitude_item_positions,
        [GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]:
          amplitude_user_typed_search_term,
        [GEV.AMPLITUDE_SEARCH_QUERY_ID]: amplitude_search_query_id,
        [GEV.AMPLITUDE_SEARCH_INDEX_NAME]: amplitude_search_index_name,
        [GEV.AMPLITUDE_EVENT_TYPE]: amplitude_event_type,
        [GEV.AMPLITUDE_EVENT_SCOPE]: amplitude_event_scope,
        [GEV.AMPLITUDE_FILTERS]: amplitude_filters,
        [GEV.AMPLITUDE_RECIPE_REVIEW_RATING]: amplitude_recipe_review_rating,
        [GEV.AMPLITUDE_RECIPE_REVIEW_DISPLAY_NAME]:
          amplitude_recipe_review_display_name,
        [GEV.AMPLITUDE_RECIPE_REVIEW_TITLE]: amplitude_recipe_review_title,
        [GEV.AMPLITUDE_RECIPE_REVIEW_TEXT]: amplitude_recipe_review_text,
        [GEV.AMPLITUDE_RECIPE_REVIEW_RECOMMENDED_ATTRIBUTE]:
          amplitude_recipe_review_recommended_attribute,
        [GEV.AMPLITUDE_RECIPE_REVIEW_EASY_TO_FOLLOW_ATTRIBUTE]:
          amplitude_recipe_review_easy_to_follow_attribute,
        [GEV.AMPLITUDE_NUMBER_OF_ACTIVE_ORDERS]:
          amplitude_number_of_active_orders,
        [GEV.AMPLITUDE_DELIVERY_ORDER_ID]: amplitude_delivery_order_id,
        [GEV.AMPLITUDE_SALES_ORDER_ID]: amplitude_sales_order_id,
        [GEV.AMPLITUDE_AMENDABLE_ORDER]: amplitude_amendable_order,
        [GEV.AMPLITUDE_CANCELLABLE_ORDER]: amplitude_cancellable_order,
        [GEV.AMPLITUDE_ORDER_AMOUNT]: amplitude_order_amount,
        [GEV.AMPLITUDE_STORE_ID]: amplitude_store_id,
        [GEV.AMPLITUDE_ORDER_QUANTITY]: amplitude_order_quantity,
        [GEV.AMPLITUDE_IS_PAST_PURCHASE]: amplitude_is_past_purchase,
        [GEV.AMPLITUDE_HOURS_TO_DELIVERY_TIMESLOT]:
          amplitude_hours_to_delivery_timeslot,
        [GEV.AMPLITUDE_DAYS_TO_DELIVERY_TIMESLOT]:
          amplitude_days_to_delivery_timeslot,
        [GEV.AMPLITUDE_DELIVERY_ORDER_STATUS]: amplitude_delivery_order_status,
        [GEV.AMPLITUDE_SALES_ORDER_STATUS]: amplitude_sales_order_status,
        [GEV.AMPLITUDE_ORDER_TYPE]: amplitude_order_type,
        [GEV.TOTAL_PRODUCT_COUNT]: total_product_count,
        [GEV.AMPLITUDE_SEARCH_TERM_SELECTED]: amplitude_search_term_selected,
        [GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME]:
          amplitude_available_slot_time,
        [GEV.AMPLITUDE_TIMESLOT_CURRENT_TIME]: amplitude_timeslot_current_time,
        [GEV.AMPLITUDE_EARLIER_DELIVERY_OPTION]:
          amplitude_earlier_delivery_option,
        [GEV.AMPLITUDE_LEAVE_ITEM_AT_DOORSTEP_OPTION]:
          amplitude_leave_item_at_doorstep_option,
        [GEV.AMPLITUDE_SELECTED_DELIVERY_INSTRUCTION]:
          amplitude_selected_delivery_instruction,
        [GEV.SEGMENT_SCREEN_CATEGORY]:
          segment_screen_category || 'grocery-online',
        [GEV.IS_SEGEMENT_EVENT]: is_segment_event,
        [GEV.SEGMENT_SUBSTITUTION_OPTION]: segment_substitution_option,
        [GEV.SEGMENT_EARLY_DELIVERY_OPTION]: segment_early_delivery_option,
        [GEV.SEGMENT_LEAVE_AT_DOORSTEP_OPTION]:
          segment_leave_at_doorstep_option,
        [GEV.SEGMENT_TOTAL_ORDERED_QUANTITY]: segment_total_ordered_quantity,
        [GEV.SEGMENT_DELIVERY]: segment_delivery,
        [GEV.SEGMENT_PRODUCT]: segment_products,
        [GEV.SEGMENT_ORDER_OFFER]: segment_order_offer,
        [GEV.SEGMENT_DISTANCE_TO_OUTLET]: segment_distance_to_outlet,
        [GEV.AMPLITUDE_BANNER_ID]: banner_id,
        [GEV.AMPLITUDE_BANNER_NAME]: banner_name,
        [GEV.AMPLITUDE_BANNER_POSITION]: banner_position,
        [GEV.AMPLITUDE_BANNER_TYPE]: banner_type,
        [GEV.AMPLITUDE_BANNER_STORE_TYPE]: store_type,
        [GEV.AMPLITUDE_BANNER_DESTINATION_LINK]: destination_link,
        [GEV.AMPLITUDE_BANNER_SCREEN_CATEGORY]: screen_category,
        [GEV.AMPLITUDE_VERTICAL]: vertical,
        [GEV.AMPLITUDE_DY_EVENT_PROPERTIES]: dy_event_properties,
        [GEV.AMPLITUDE_DY_ENGAGEMENT_PROPERTIES]: dy_engagement_properties,
        [GEV.AMPLITUDE_DY_DESTINATIONS]: destinations,
        [GEV.AMPLITUDE_DY_ID]: dyid,
        [GEV.AMPLITUDE_DY_SESSION]: dysession,
        [GEV.AMPLITUDE_DY_ORDER_TYPE]: orderType,
        [GEV.AMPLITUDE_DY_SDT]: sdt,
        [GEV.AMPLITUDE_BANNER_CALL_TO_ACTION]: call_to_action_location,
        [GEV.AMPLITUDE_BANNER_CALL_TO_ACTION_SUB_LOCATION]:
          call_to_action_sublocation,
        [GEV.TRACK]: track,
        [GEV.AMPLITUDE_IS_VOUCHER_AVAILABLE]: is_voucher_available,
        [GEV.AMPLITUDE_IS_VOUCHER_SHOWN]: is_voucher_shown,
        [GEV.AMPLITUDE_VOUCHER_OFFER_ID]: offer_id,
        [GEV.GA_MESSAGE]: message,
      },
      ...filterTrackingData,
      ...suggestionImpression,
    }

    if (cart_product_checked) {
      GTEvent[GEV.CART_PRODUCT_CHECKED] = cart_product_checked
    }

    if (cart_product_unchecked) {
      GTEvent[GEV.CART_PRODUCT_UNCHECKED] = cart_product_unchecked
    }

    if (cartinstocklabel) {
      GTEvent[GEV.CART_IN_STOCK_LABEL] = cartinstocklabel
    }

    if (url) {
      GTEvent[[GEV.URL]] = url
    }

    if (is_lower_case_logged_in_status) {
      GTEvent[GEV.LOGIN_STATUS] = GTEvent[GEV.LOGIN_STATUS].toLowerCase()
    }

    if (isLoggedIn) {
      const lastOrderDate = getNCheck(accountData, 'lastOrderedOn', '')
      const formattedDate = lastOrderDate
        ? format(new Date(lastOrderDate), 'YYYY-MM-DD')
        : ''

      GTEvent[[GEV.LAST_ORDER_DATE]] = formattedDate
      GTEvent[[GEV.USER_ID]] = getNCheck(accountData, 'id')

      GTEvent[[GEV.AMPLITUDE_USER_ACCOUNT_TYPE]] =
        _getAccountType(accountData) || null
      GTEvent[[GEV.AMPLITUDE_USER_LINK_PLUS_ID]] = getNCheck(
        accountData,
        'association.linkplus'
      )
      GTEvent[[GEV.AMPLITUDE_USER_LINKPASS_ID]] = getNCheck(accountData, 'uid')
      GTEvent[[GEV.AMPLITUDE_LOGIN_USER_TYPE]] = amplitude_login_user_type
      GTEvent[[GEV.AMPLITUDE_USER_LOGIN_SIGNUP_SOURCE]] =
        auth_signup_entry_source
    } else {
      GTEvent[[GEV.REG_DATE]] = undefined
      GTEvent[[GEV.LAST_ORDER_DATE]] = undefined
      GTEvent[[GEV.USER_ID]] = undefined
      GTEvent[[GEV.AMPLITUDE_USER_ACCOUNT_TYPE]] = null
      GTEvent[[GEV.AMPLITUDE_USER_LINK_PLUS_ID]] = null
      GTEvent[[GEV.AMPLITUDE_USER_LINKPASS_ID]] = null
      GTEvent[[GEV.AMPLITUDE_LOGIN_USER_TYPE]] = null
      GTEvent[[GEV.AMPLITUDE_USER_LOGIN_SIGNUP_SOURCE]] = null
    }

    if (checkoutAddress) {
      // segment new properyty
      GTEvent[[GEV.SEGMENT_STORE_TYPE]] =
        segment_store_type || checkoutAddress?.storeType

      let fulFilment = ''
      if (checkoutAddress && checkoutAddress.type === 'delivery') {
        fulFilment = 'Home Delivery'
      } else if (checkoutAddress && checkoutAddress.type === 'pickup') {
        fulFilment = 'Click & Collect'
      }

      GTEvent[[GEV.FUL_TYPE]] = fulFilment
      GTEvent[[GEV.POSTAL_CODE]] = hexSHA256String(
        getNCheck(checkoutAddress, 'pincode', '')
      )
      GTEvent[[GEV.LIST_CAT]] =
        listing_catalog || getNCheck(checkoutAddress, 'clientId', '').toString()
      GTEvent[[GEV.STORE_ID]] =
        listing_catalog || getNCheck(checkoutAddress, 'clientId', '').toString()
      GTEvent[[GEV.DBP_STORE_ID]] = getNCheck(
        checkoutAddress,
        'storeId',
        ''
      ).toString()
    }

    if (!window.dataLayer || window.dataLayer.length >= 1000) {
      return
    }
    // send event to GA
    window.dataLayer.push(GTEvent)

    // send event to Amplitude
    sendGaEventToAmplitude(
      {
        ...GTEvent,
        [GEV.AMPLITUDE_FULFILMENT_TYPE_IN_ARRAY]:
          amplitude_fulfilment_type_in_array,
      },
      this.amplitude,
      this.props.userKey
    )

    if (!SPLIT_EXCLUDE_LIST.includes(GTEvent.event)) {
      const cleanedEvent = this.clean(GTEvent)
      this.state.splitClient &&
        this.state.splitClient.track(modifiedEvent, null, cleanedEvent)
    }
  }

  render() {
    const { children } = this.props
    return <Provider value={this.state}>{children}</Provider>
  }
}

const EventTrackingProviderWrapper = props => (
  <GlobalContext.Consumer>
    {({ experiments, remoteConfig, multiVariantExperiments }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => (
          <AccountConsumer>
            {({ accountData, isLoggedIn }) => (
              <EventTrackingProvider
                {...props}
                experiments={experiments}
                remoteConfig={remoteConfig}
                accountData={accountData}
                isLoggedIn={isLoggedIn}
                checkoutAddress={checkoutAddress}
                multiVariantExperiments={multiVariantExperiments}
              />
            )}
          </AccountConsumer>
        )}
      </CheckoutAddressConsumer>
    )}
  </GlobalContext.Consumer>
)

EventTrackingProviderWrapper.defaultProps = {}

export const EventTrackingConsumer = Consumer
export { EventTrackingContext }

export default EventTrackingProviderWrapper
