import isNil from 'lodash/isNil'
import get from 'lodash/get'
import sha256 from 'js-sha256'

const FILTER_DIMENSIONS = {
  FIL_ITRC_COUNTRY_OF_ORIGIN: 'filter_interaction_country_of_origin',
  FIL_ITRC_DIETARY_ATTRIBUTES: 'filter_interaction_dietary_attributes',
  FIL_ITRC_BRAND: 'filter_interaction_brand',
  FIL_ITRC_PRICE: 'filter_interaction_price',
  FIL_ITRC_TAG: 'filter_interaction_tag',
}

const EVENT_CATEGORIES = {
  PRODUCT_CORE: 'Product-Core',
}

const SPLIT_EVENT = 'split_event'

// All variables needed for GTM tracking
// Attributes prefixed with `amplitude` are sent to amplitude only
const GTM_ENV_VAR = {
  ENV: 'environment',
  PROD_ID: 'product_id',
  PROD_NAME: 'product_name',
  GA_MESSAGE: 'message',
  PROD_CAT_L: 'product_category',
  PROD_CAT_L1: 'product_category_l1',
  PROD_CAT_L2: 'product_category_l2',
  PROD_CAT_L3: 'product_category_l3',
  PROD_CAT_L4: 'product_category_l4',
  PROD_CUR: 'product_currency',
  PROD_QUANT: 'product_quantity',
  SORT_FILTER: 'sort_filter',
  MEMBER_TYPE: 'member_type',
  LOGIN_STATUS: 'loggedin_status',
  USER_TYPE: 'user_type',
  USER_ID: 'user_id',
  STORAGE: 'storage_instructions',
  SEARCH_TERM: 'search_term',
  PROD_BRAND: 'product_brand',
  RETAIL_PRICE: 'retail_price',
  OFFER_PRICE: 'offer_price',
  PROMO_TEXT: 'promo_text',
  PROD_CAT: 'product_catalog',
  INVTRY_COUNT: 'inventory_count',
  PAGE_TYPE: 'page_type',
  LIST_CAT: 'listing_catalog',
  DBP_STORE_ID: 'dbp_store_id',
  STORE_ID: 'store_id',
  SEARCH_FEATURES_COUNT: 'search_features_count',
  SEARCH_RES_COUNT: 'search_result_count',
  REG_DATE: 'registration_date',
  FIR_ORDER_DATE: 'first_order_date',
  LAST_ORDER_DATE: 'last_order_date',
  MEMSH_TYPE: 'membership_type',
  FUL_TYPE: 'fulfilment_type',
  AMPLITUDE_PRODUCT_FULFILMENT_TYPE: 'amplitude_product_fulfilment_type',
  FUL_CENTER: 'fulfilment_centre',
  POSTAL_CODE: 'postal_code',
  PAY_TYPE: 'payment_type',
  PROMO_CODE: 'promo_code',
  AD_BLOCKER_STATUS: 'adblocker_status',
  TRANC_NO: 'transaction_number',
  ASSET_TYPE: 'asset_type',
  SELL_NAME: 'seller_name',
  USER_AGENT: 'user_agent',
  EVENT: 'event',
  CONTENT_TYPE: 'content_type',
  CONTENT_ID: 'content_id',
  EVENT_CAT: 'event_category',
  EVENT_ACTION: 'event_action',
  EVENT_LABEL: 'event_label',
  TRANSC_AFFIL: 'transactionAffiliation',
  TRACK: 'track',
  SKU: 'sku',
  PRICE: 'price',
  QUANTITY: 'quantity',
  NAME: 'name',
  SELLER: 'seller',
  CATEGORY: 'category',
  TRANS_TOTAL: 'transactionTotal',
  TRANS_TAX: 'transactionTax',
  TRANS_SHIPPING: 'transactionShipping',
  CURRENCY: 'currency',
  TRANS_PRODUCTS: 'transactionProducts',
  TRANSC_ID: 'transactionId',
  URL: 'url',
  KEYWORD_SELECTED: 'keyword_selected',
  SEARCH_POSITION: 'search_position',
  ECOMMERCE: 'ecommerce',
  PRODUCTS: 'products',
  ACTION_FIELD: 'actionField',
  ACTION_FIELD_STEP: 'step',
  ACTION_FIELD_OPTION: 'option',
  SUGGESTED_KEYWORD_IMPRESSION: 'suggested_keyword_impression',
  SUGGESTED_SELLER_IMPRESSION: 'suggested_seller_impression',
  SUGGESTED_CAMPAIGN_IMPRESSION: 'suggested_campaign_impression',
  SUGGESTED_BRAND_IMPRESSION: 'suggested_brand_impression',
  SUGGESTED_SEARCH_IMPRESSION: 'suggested_search_impression',
  SUGGESTED_RECENT_IMPRESSION: 'suggested_recent_impression',
  GA4_SUGGESTED_CAMPAIGN_IMPRESSION: 'ga4_suggested_campaign_impression',
  GA4_SUGGESTED_KEYWORD_IMPRESSION: 'ga4_suggested_keyword_impression',
  GA4_SUGGESTED_SEARCH_IMPRESSION: 'ga4_suggested_search_impression',
  GA4_SUGGESTED_RECENT_IMPRESSION: 'ga4_suggested_recent_impression',
  GA4_SUGGESTED_REFINEMENT_IMPRESSION: 'ga4_suggested_refinement_impression',
  CART_ID_ZOP: 'cart_id_zop',
  PRODUCT_ID_ZOP: 'product_id_zop',
  CART_ID_WCS: 'cart_id_wcs',
  CART_GROSS_VALUE: 'cart_gross_value',
  CART_NET_VALUE: 'cart_net_value',
  CART_DETAIL: 'cart_detail',
  INVENTORY_STATUS: 'inventory_status',
  PRODUCT_POSITION: 'product_position',
  CATEGORY_ID: 'category_id',
  CATEGORY_NAME: 'category_name',
  BRAND_ID: 'brand_id',
  BRAND_NAME: 'brand_name',
  ECOMM_NAME: 'name',
  ECOMM_ID: 'id',
  ECOMM_PRODUCT_ID_ZOP: 'product_id_zop',
  ECOMM_PRICE: 'price',
  ECOMM_SELLING_PRICE: 'selling_price',
  ECOMM_BRAND: 'brand',
  ECOMM_CATEGORY: 'category',
  ECOMM_DIMENSION32: 'dimension32',
  ECOMM_DIMENSION60: 'dimension60',
  ECOMM_IS_PAST_PURCHASE: 'dimension115',
  ECOMM_VARIANT: 'variant',
  ECOMM_POSITION: 'position',
  ECOMM_LIST: 'list',
  CUSTOM_CHECKOUT_STEP: 'custom_checkout_step',
  ITEM_ID: 'item_id',
  ITEM_NAME: 'item_name',
  ECOMM_DIMENSION61: 'dimension61',
  ECOMM_OFFER_PRICE: 'offer_price',
  ECOMM_PROMO_TEXT: 'promo_text',
  ECOMM_CREATIVE: 'creative',
  ECOMM_QUANTITY: 'quantity',
  TESTING_VERSION: 'testing_version',
  RELEASE_VERSION: 'release_version',
  JWC_ID: 'jwc_id',
  SCREEN_NAME: 'screen_name',
  JWC_MEMBERSHIP: 'jwc_membership',
  AGE_ABOVE_18: 'age_above_18',
  JWC_CONSENTPROMOTION: 'jwc_consentpromotion',
  JWC_TRANS_ID: 'transactionId',
  JWC_OPT_MEMBERSHIP_CARD: 'opt_membershipcard',
  JWC_REFERRER: 'referrer',
  AFFILIATION: 'affiliation',
  COUPON: 'coupon',
  SHIPPING: 'shipping',
  DIMENSION44: 'dimension44',
  DIMENSION13: 'dimension13',
  DIMENSION43: 'dimension43',
  DIMENSION23: 'dimension23',
  DIMENSION24: 'dimension24',
  DIMENSION34: 'dimension34',
  DIMENSION40: 'dimension40',
  DIMENSION41: 'dimension41',
  DIMENSION48: 'dimension48',
  DIMENSION63: 'dimension63',
  DIMENSION64: 'dimension64',
  DIMENSION84: 'dimension84',
  DIMENSION89: 'dimension89',
  DIMENSION90: 'dimension90',
  DIMENSION91: 'dimension91',
  DIMENSION97: 'dimension97',
  DIMENSION101: 'dimension101',
  DIMENSION102: 'dimension102',
  DIMENSION106: 'dimension106',
  IS_LOWER_CASE_LOGGED_IN_STATUS: 'is_lower_case_logged_in_status',
  ALGOLIA_EXP: 'algolia_exp',
  SNIPPET_VERSION: 'snippet_version',
  SCREEN_NAME_GA4: 'screenName',
  TAX: 'tax',
  REVENUE: 'revenue',
  SLUG: 'slug',
  AUTH_SIGNUP_ENTRY_SOURCE: 'auth_signup_entry_source',
  APPLIED_PROMO: 'applied_promo',
  UNAPPLIED_PROMO: 'unapplied_promo',
  PROMO_TYPE: 'promo_type',
  UTM_CONTENT: 'utm_content',
  STATE: 'state',
  DEVICE_ID: 'device_id',
  CART_PRODUCT_CHECKED: 'cart_product_checked',
  CART_PRODUCT_UNCHECKED: 'cart_product_unchecked',
  CART_IN_STOCK_LABEL: 'cartinstocklabel',
  RECIPE_ID: 'recipe_id',
  RECIPE_TITLE: 'recipe_title',
  RECIPE_ADJUSTED_SERVING: 'recipe_adjusted_serving',
  AMPLITUDE_EVENT_NAME: 'amplitude_event_name',
  PRODUCT_RATING: 'rating', // sent to amplitude only
  PROMOTION_COUNT: 'promotion_count', // sent to amplitude only
  PROMOTION_TYPES: 'promotion_types', // sent to amplitude only
  PROMOTION_DESCRIPTIONS: 'promotion_descriptions', // sent to amplitude only
  AMPLITUDE_CTA_SUBLOCATION: 'amplitude_cta_sublocation',
  AMPLITUDE_CTA_LOCATION: 'amplitude_cta_location',
  AMPLITUDE_VOUCHER_POSITION: 'voucher_position',
  AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION:
    'amplitude_2click_call_to_action_location',
  AMPLITUDE_PRODUCT_QUANTITY: 'amplitude_product_quantity',
  AMPLITUDE_PRODUCT_QUANTITIES: 'amplitude_product_quantities',
  AMPLITUDE_SEARCH_FEATURE_SELECTED: 'amplitude_search_feature_selected',
  AMPLITUDE_FINAL_ORDER_VALUE: 'amplitude_final_order_value',
  AMPLITUDE_FINAL_PAYMENT_VALUE: 'amplitude_final_payment_value',
  AMPLITUDE_PAYMENT_OPTION: 'amplitude_payment_option',
  AMPLITUDE_PROMO_CODE: 'amplitude_promo_code',
  AMPLITUDE_TOTAL_CART_VALUE: 'amplitude_total_cart_value',
  AMPLITUDE_DELIVERY_TIMESLOT: 'amplitude_delivery_timeslot',
  AMPLITUDE_REDEEM_LINKPOINT_OPTION: 'amplitude_redeem_linkpoint_option',
  AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS: 'amplitude_days_to_available_slots',
  AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS: 'amplitude_hours_to_available_slots',
  AMPLITUDE_DAYS_TO_SELECTED_SLOT: 'amplitude_days_to_selected_slot',
  AMPLITUDE_HOURS_TO_SELECTED_SLOT: 'amplitude_hours_to_selected_slot',
  AMPLITUDE_SLOTS_AVAILABILITY_STATUS: 'amplitude_slots_availability_status',
  AMPLITUDE_CATEGORY_NAME: 'amplitude_category_name',
  AMPLITUDE_CATEGORY_LEVEL: 'amplitude_category_level',
  AMPLITUDE_LOGIN_USER_TYPE: 'amplitude_login_user_type',
  AMPLITUDE_USER_LINK_PLUS_ID: 'amplitude_user_link_plus_id',
  AMPLITUDE_USER_LINKPASS_ID: 'amplitude_user_linkpass_id',
  AMPLITUDE_USER_ACCOUNT_TYPE: 'amplitude_user_account_type',
  AMPLITUDE_USER_LOGIN_SIGNUP_SOURCE: 'amplitude_user_login_signup_source',
  AMPLITUDE_VENDOR_ID: 'amplitude_vendor_id',
  AMPLITUDE_VENDOR_NAME: 'amplitude_vendor_name',
  AMPLITUDE_SORT_BY_CATEGORY: 'amplitude_sort_by_category',
  AMPLITUDE_FILTER_CATEGORY: 'amplitude_filter_category',
  AMPLITUDE_FILTER_DETAILS: 'amplitude_filter_details',
  AMPLITUDE_VOUCHER_CODE: 'amplitude_voucher_code',
  AMPLITUDE_VOUCHER_TYPE: 'amplitude_voucher_type',
  AMPLITUDE_HANDLING_TIME_COUNT: 'amplitude_number_of_days',
  AMPLITUDE_MESSAGE: 'amplitude_message',
  AMPLITUDE_PRODUCT_NAMES: 'amplitude_product_names',
  AMPLITUDE_LIST_CATS: 'amplitude_product_skus',
  AMPLITUDE_DELIVERY_FEE: 'amplitude_delivery_fee',
  AMPLITUDE_SERVICE_FEE: 'amplitude_service_fee',
  AMPLITUDE_LINKPOINT_REDEMPTION: 'amplitude_linkpoint_redemption',
  AMPLITUDE_ORDER_PROMOTION_AMOUNT: 'order_promotion_amount',
  AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE:
    'amplitude_cta_sublocation_section_type',

  MATCH_TYPE: 'match_type',
  AMPLITUDE_RECIPE_TITLE: 'amplitude_recipe_title',
  AMPLITUDE_RECIPE_ID: 'amplitude_recipe_id',
  AMPLITUDE_RECIPE_CATEGORY: 'amplitude_recipe_category',
  AMPLITUDE_RECIPE_PREPARATION_TIME: 'amplitude_recipe_preparation_time',
  AMPLITUDE_RECIPE_COOKING_TIME: 'amplitude_recipe_cooking_time',
  AMPLITUDE_RECIPE_COOKING_LEVEL: 'amplitude_recipe_cooking_level',
  AMPLITUDE_RECIPE_SERVING_SIZE: 'amplitude_recipe_serving_size',
  AMPLITUDE_RECIPE_NUMBER_OF_INGREDIENTS:
    'amplitude_recipe_number_of_ingredients',
  AMPLITUDE_RECIPE_NUMBER_OF_STEPS: 'amplitude_recipe_number_of_steps',
  AMPLITUDE_RECIPE_MEDIA_TYPE: 'amplitude_recipe_media_type',
  AMPLITUDE_RECIPE_AVERAGE_RATING: 'amplitude_recipe_average_rating',
  AMPLITUDE_RECIPE_RATING_COUNT: 'amplitude_recipe_rating_count',
  AMPLITUDE_RECIPE_VIDEO_DURATION: 'amplitude_recipe_video_duration',
  AMPLITUDE_RECIPE_STEP_NUMBER: 'amplitude_recipe_step_number',
  AMPLITUDE_PERCENTAGE_WATCHED: 'amplitude_percentage_watched',
  AMPLITUDE_RECIPE_REVIEW_RATING: 'amplitude_recipe_review_rating',
  AMPLITUDE_RECIPE_REVIEW_DISPLAY_NAME: 'amplitude_recipe_review_display_name',
  AMPLITUDE_RECIPE_REVIEW_TITLE: 'amplitude_recipe_review_title',
  AMPLITUDE_RECIPE_REVIEW_TEXT: 'amplitude_recipe_review_text',
  AMPLITUDE_RECIPE_REVIEW_RECOMMENDED_ATTRIBUTE:
    'amplitude_recipe_review_recommended_attribute',
  AMPLITUDE_RECIPE_REVIEW_EASY_TO_FOLLOW_ATTRIBUTE:
    'amplitude_recipe_review_easy_to_follow_attribute',
  AMPLITUDE_SEARCH_POSITIONS: 'amplitude_search_positions',
  AMPLITUDE_ITEM_POSITIONS: 'amplitude_item_positions',
  AMPLITUDE_USER_TYPED_SEARCH_TERM: 'amplitude_user_typed_search_term',
  AMPLITUDE_SEARCH_QUERY_ID: 'amplitude_search_query_id',
  AMPLITUDE_SEARCH_INDEX_NAME: 'amplitude_search_index_name',
  AMPLITUDE_EVENT_TYPE: 'amplitude_event_type',
  AMPLITUDE_EVENT_SCOPE: 'amplitude_event_scope',
  AMPLITUDE_FILTERS: 'amplitude_filters',
  AMPLITUDE_NUMBER_OF_ACTIVE_ORDERS: 'amplitude_number_of_active_orders',
  AMPLITUDE_DELIVERY_ORDER_STATUS: 'amplitude_delivery_order_status',
  AMPLITUDE_SALES_ORDER_STATUS: 'amplitude_sales_order_status',
  AMPLITUDE_DELIVERY_ORDER_ID: 'amplitude_delivery_order_id',
  AMPLITUDE_SALES_ORDER_ID: 'amplitude_sales_order_id',
  AMPLITUDE_AMENDABLE_ORDER: 'amplitude_amendable_order',
  AMPLITUDE_CANCELLABLE_ORDER: 'amplitude_cancellable_order',
  AMPLITUDE_ORDER_AMOUNT: 'amplitude_order_amount',
  AMPLITUDE_ORDER_QUANTITY: 'amplitude_order_quantity',
  AMPLITUDE_STORE_ID: 'amplitude_store_id',
  AMPLITUDE_HOURS_TO_DELIVERY_TIMESLOT: 'amplitude_hours_to_delivery_timeslot',
  AMPLITUDE_DAYS_TO_DELIVERY_TIMESLOT: 'amplitude_days_to_delivery_timeslot',
  AMPLITUDE_ORDER_TYPE: 'amplitude_order_type',
  TOTAL_PRODUCT_COUNT: 'total_product_count',
  AMPLITUDE_SEARCH_TERM_SELECTED: 'amplitude_search_term_selected',
  AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME: 'amplitude_available_slot_time',
  AMPLITUDE_TIMESLOT_CURRENT_TIME: 'amplitude_timeslot_current_time',
  AMPLITUDE_VERTICAL: 'vertical',
  AMPLITUDE_CALL_TO_ACTION: 'call to action location',
  AMPLITUDE_CALL_TO_ACTION_SUB_LOCATION: 'call to action sublocation',
  AMPLITUDE_SAP_PRODUCT_ID: 'sap product id',
  AMPLITUDE_DBP_PRODUCT_ID: 'dbp product id',
  VOUCHER_CODE_ERROR_MESSAGE: 'error message',
  VOUCHER_CODE_ADD_METHOD: 'add method',
  VOUCHER_CODE_VOUCHER_CODE: 'voucher_code',
  VOUCHER_CODE_VOUCHER_TYPE: 'voucher_type',
  VOUCHER_CODE_NO_OF_VOUCHERS: 'number_of_vouchers_available',
  VOUCHER_CODE_NO_OF_VOUCHERS_APPLIED: 'number_of_vouchers_applied',
  SAP_STORE_ID: 'sap_store_id',
  AMPLITUDE_IS_PAST_PURCHASE: 'amplitude_is_past_purchase',
  AMPLITUDE_OFFER_ID: 'offer id',
  SEC_OFFER_VERTICAL: 'offer vertical',
  SEC_LANDING_PAGE: 'landing page',
  PROMOTION_ID: 'promotion_id',
  ITEMS: 'items',
  PROMOTION_NAME: 'promotion_name',
  CREATIVE_NAME: 'creative_name',
  CREATIVE_SLOT: 'creative_slot',
  AMPLITUDE_EXPERIMENT: 'amplitude_experiment',
  AMPLITUDE_PRODUCT_HOURS_TO_AVAILABLE_SLOT:
    'amplitude_product_hours_to_available_slot',
  AMPLITUDE_PRODUCT_DAYS_TO_AVAILABLE_SLOT:
    'amplitude_product_days_to_available_slot',
  AMPLITUDE_IS_MKP_ITEMS: 'amplitude_mkp_item',
  AMPLITUDE_POTENTIAL_TIMESLOT_SHOWN: 'amplitude_potential_timeslot_shown',
  AMPLITUDE_POTENTIAL_HOURS_TO_AVAILABLE_SLOT:
    'amplitude_potential_hours_to_available_slot',
  AMPLITUDE_POTENTIAL_DAYS_TO_AVAILABLE_SLOT:
    'amplitude_potential_days_to_available_slot',
  AMPLITUDE_ORDER_PROMOTION_TYPE: 'amplitude_order_promotion_type',
  AMPLITUDE_ORDER_PROMOTION_DESCRIPTION:
    'amplitude_order_promotion_description',
  AMPLITUDE_FULFILMENT_TYPE_IN_ARRAY: 'amplitude_fulfilment_type_in_array',
  AMPLITUDE_EARLIER_DELIVERY_OPTION: 'amplitude_earlier_delivery_option',
  AMPLITUDE_LEAVE_ITEM_AT_DOORSTEP_OPTION:
    'amplitude_leave_item_at_doorstep_option',
  AMPLITUDE_SELECTED_DELIVERY_INSTRUCTION:
    'amplitude_selected_delivery_instruction',
  AMPLITUDE_BANNER_ID: 'banner_id',
  AMPLITUDE_BANNER_NAME: 'banner_name',
  AMPLITUDE_BANNER_POSITION: 'banner_position',
  AMPLITUDE_BANNER_TYPE: 'banner_type',
  AMPLITUDE_BANNER_STORE_TYPE: 'store_type',
  AMPLITUDE_BANNER_DESTINATION_LINK: 'destination_link',
  AMPLITUDE_BANNER_SCREEN_CATEGORY: 'screen_category',
  AMPLITUDE_BANNER_CALL_TO_ACTION: 'call_to_action_location',
  AMPLITUDE_BANNER_CALL_TO_ACTION_SUB_LOCATION: 'call_to_action_sublocation',
  AMPLITUDE_DY_EVENT_PROPERTIES: 'dy_event_properties',
  AMPLITUDE_DY_ENGAGEMENT_PROPERTIES: 'dy_engagement_properties',
  AMPLITUDE_DY_DESTINATIONS: 'destinations',
  AMPLITUDE_DY_ID: 'dyid',
  AMPLITUDE_DY_SESSION: 'dysession',
  AMPLITUDE_DY_ORDER_TYPE: 'orderType',
  AMPLITUDE_DY_SDT: 'sdt',
  IS_SEGEMENT_EVENT: 'is_segment_event',
  SEGMENT_STORE_TYPE: 'segment_store_type',
  SEGMENT_SCREEN_CATEGORY: 'segment_screen_category',
  SEGMENT_FAIRPRICE_PAYMENT_OPTION: 'segment_fairprice_payment_option',
  SEGMENT_SUBSTITUTION_OPTION: 'segment_substitution_option',
  SEGMENT_EARLY_DELIVERY_OPTION: 'segment_early_delivery_option',
  SEGMENT_LEAVE_AT_DOORSTEP_OPTION: 'segment_leave_at_doorstep_option',
  SEGMENT_TOTAL_ORDERED_QUANTITY: 'segment_total_ordered_quantity',
  SEGMENT_DISTANCE_TO_OUTLET: 'segment_distance_to_outlet',
  SEGMENT_DELIVERY: 'segment_delivery',
  SEGMENT_ORDER_OFFER: 'segment_order_offer',
  SEGMENT_PRODUCT: 'segment_products',
  AMPLITUDE_IS_VOUCHER_AVAILABLE: 'is_voucher_available',
  AMPLITUDE_IS_VOUCHER_SHOWN: 'is_voucher_shown',
  AMPLITUDE_VOUCHER_OFFER_ID: 'offer_id',
  AMPLITUDE_CALL_TO_ACTION_LOCATION: 'amplitude_call_to_action_location',
  AMPLITUDE_IS_VOUCHER_AVAILABLE_KEY: 'amplitude_is_voucher_available',
  AMPLITUDE_IS_VOUCHER_SHOWN_KEY: 'amplitude_is_voucher_shown',
  AMPLITUDE_OFFER_ID_KEY: 'amplitude_offer_id',
  ...FILTER_DIMENSIONS,
}

const SORT_DEFAULT_VALUES = [
  { displayName: 'Relevancy', slug: 'RELEVANCE' },
  { displayName: 'Newest', slug: 'NEW_FIRST' },
  { displayName: 'Price: Low to high', slug: 'PRICE_ASC' },
  { displayName: 'Price: High to low', slug: 'PRICE_DESC' },
  { displayName: 'Brand: A-Z', slug: 'A-Z' },
  { displayName: 'Brand: Z-A', slug: 'Z-A' },
]

const PAGE_TYPES = {
  HOME: 'Home',
  PROMO: 'promotion',
  PRODUCT_DETAIL: 'Product Detail',
  CART: 'Cart',
  SEARCH_RESULT: 'Search Result',
  SHOPPINGLIST: 'Others',
  OTHERS: 'Others',
  CATEGORIES: 'Categories',
  LISTING_DETAIL: 'Listing Detail',
  BRAND: 'brand',
  ORDER_HISTORY: 'Order History',
  PROFILE: 'Profile',
  FAQ: 'FAQ',
  CORP_PAGE: 'Corp Page',
  MORE: 'Others',
  ACCOUNT: 'Profile',
  ORDERS: 'Order',
  HELP: 'Others',
  RECIPES: 'Others',
  TIPS: 'Others',
  WEEKLY_ADS: 'Others',
  STORE_LOCATOR: 'Others',
  EVENTS: 'Others',
  LUCKY_DRAW: 'Others',
  JWC: 'Others',
  SHOP_N_DONATE: 'Others',
  BUSINESS: 'Others',
  TNC: 'Others',
  PRIVACY: 'Others',
  PCM: 'Others',
  CHECKOUT: 'Checkout',
  LOGIN: 'Profile',
  REGISTER: 'Profile',
  CART_SUMMARY: 'Cart Summary Page',
  JWC_HOMEPAGE: 'Homepage',
  JWC_REGISTRATION: 'Registration',
  JWC_PAYMENT_METHOD: 'Payment_Method',
  CATEGORY: 'category',
  PRODUCT: 'product',
  PRODUCT_LISTING: 'product_listing',
  SEARCH: 'search',
  TAG: 'tag', // for API calls
  TAGS: 'tags', // for tracking
  MICRO: 'Micro', // Membership signup page
  PAYMENT: 'Payment', // Membership checkout payment page
  CONFIRMATION: 'Confirmation', // Membership confirmation page
  LINK_PLUS: 'Link Plus',
  VOUCHER_WALLET: 'Voucher Wallet',
  VOUCHER_CODE: 'Voucher Code',
  EVOUCHER: 'E-Voucher',
  CAMPAIGN_LUCKY_DRAW: 'lucky draw',
  RECIPE: 'Recipe',
  RECIPE_DETAIL: 'Recipe Detail',
  RECIPE_LISTING: 'RecipeListing',
  RECIPE_HOME: 'recipe home',
  RECIPE_CATEGORY: 'recipe category',
  RECIPE_SEARCH: 'recipe search',
  RECIPE_REVIEW_FORM: 'review form',
  PROMOTION: 'Promotion',
}

// This function recursively fetch all parent category of a product.
function fetchCategory(data) {
  if (!data) {
    return { product_category: '' }
  }
  if (!data.parentCategory) {
    return { product_category: data.name }
  }
  const json = fetchCategory(data.parentCategory)
  const key = `product_category_l${Object.keys(json).length}`
  json[key] = data.name
  return json
}

function fetchCategoryWithId(data) {
  if (!data) {
    return {
      product_category: {
        id: '',
        name: '',
      },
    }
  }
  if (!data.parentCategory) {
    return {
      product_category: {
        id: data.id,
        name: data.name,
      },
    }
  }
  const json = fetchCategoryWithId(data.parentCategory)
  const key = `product_category_l${Object.keys(json).length}`
  json[key] = {
    id: data.id,
    name: data.name,
  }
  return json
}

function fetchCategoryId(data) {
  if (!data) {
    return { product_category_id: '' }
  }
  if (!data.parentCategory) {
    return { product_category_id: data.id }
  }
  const json = fetchCategoryId(data.parentCategory)
  const key = `product_category_id_l${Object.keys(json).length}`
  json[key] = data.id
  return json
}

// This function to convert string into SHA256 and then into a HEX string
function hexSHA256String(str) {
  const hash = sha256.create()
  hash.update(str)
  return hash.hex()
}

// Function to safely access the value of dictonary. Returns optional value if fail to access.
function getNCheckFn(obj, key, optional = '') {
  const result = get(obj, key, optional)
  if (isNil(result)) {
    return optional
  } else {
    return result.toString()
  }
}

function getCategoryIds(category) {
  const result = fetchCategoryId(category) || {}
  return Object.values(result).join(',')
}

export {
  fetchCategory,
  fetchCategoryWithId,
  fetchCategoryId,
  hexSHA256String,
  getNCheckFn,
  GTM_ENV_VAR,
  PAGE_TYPES,
  SORT_DEFAULT_VALUES,
  FILTER_DIMENSIONS,
  EVENT_CATEGORIES,
  SPLIT_EVENT,
  getCategoryIds,
}
