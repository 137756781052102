import React from 'react'
import Router, { withRouter } from 'next/router'
import { AccountConsumer } from '../AccountProvider'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider'
import qs from 'query-string'
import dynamic from 'next/dynamic'
import { post, get, headers } from '../../lib/fetch'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { SPLIT_FEATURES } from '../../utils/constants'
import { GMC_COOKIE_KEYS } from '../../utils/constants/general'
import { getConfigFor, getExpConfig } from '../../utils/configService'

const DroppedProductsPopup = dynamic(() => import('../DroppedProductsPopup'))
const BulkProductPopup = dynamic(() =>
  import('../BulkProductPopup/BulkProductPopup')
)
const ReachedStoreBulkPopup = dynamic(() => import('../ReachedStoreBulkPopup'))
const UnavailableProductPopup = dynamic(() =>
  import('../UnavailableProductPopup/UnavailableProductPopup')
)
const ProductQuantityAdjustmentMessage = dynamic(() =>
  import('../ProductQuantityAdjustmentMessage')
)
import { getProperAddressKey } from '../../lib/addressSearch'
import getConfig from 'next/config'
import { generateQueryString } from '../../lib/filterFormatter'
import LocalStorageService from '../../utils/LocalStorageService'
import { getUserType } from '../../utils/userType'
import GlobalContext from '../GlobalContext'
import nookies from 'nookies'
import { getCurrentHost } from '../../utils/Utils'
import { getDefaultCard } from '../../lib/utils'
import {
  returnItemCountFromDropOffState,
  mergeUnavailableItemsToItems,
  modifyBulkItemsBasedOnPfcAvailability,
  getTimeStamp,
  determineSwitchedStoreType,
  PFCFFS,
  FFSPFC,
  modifyLocalItemsAccordingToLoginState,
  getCartFromResponse,
  allEqualCounts,
  totalProductCount,
  findBulkOrderProducts,
  consolidateSellerItemData,
  mergeAllSimilarDroppedOffItems,
  findOtherOffers,
  findDropOff,
  getLastCartWithNewTimeStamp,
  findPOAInapplicableItems,
  extractAmendableDeliveryOrders,
  getMaxAllowableQuantity,
} from './utils'
import { PostOrderAmendConsumer } from '../PostOrdeAmendProvider/PostOrderAmendProvider'
import CartFeedbackWrapper from '../CartFeedback/CartFeedback'
import uniqueId from 'lodash/uniqueId'

const PRODUCT_QUANTITY_ADJUSTMENT_MESSAGE =
  'We have revised your order to the quantity available.'

const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60

const {
  publicRuntimeConfig: { API_URL, PUBLIC_API_URL },
} = getConfig()

const MIN_AMOUNT_ALLOWED = 1

export const Context = React.createContext({
  items: {},
  allCartOffers: [],
  sellersItems: [],
  count: 0,
  cartErrors: null,
  includes: () => {},
  update: () => {},
  destroy: () => {},
  selectProduct: () => {},
  countOf: () => {},
  totalPrice: () => {
    return {
      totalAmount: 0,
      totalDiscount: 0,
    }
  },
  storeSyncCart: () => {},
  updateCart: () => {},
})

export const store = key => () => {
  try {
    return JSON.parse(LocalStorageService.getItem(key) || '{}')
  } catch (error) {
    return {}
  }
}

async function getProductAvailability(products, storeId) {
  const slugs = products.map(item => item?.product?.slug || item?.slug)

  if (!slugs || !slugs.length) {
    return null
  }

  const apiQueries = {
    filter: `slug:${slugs.join(',')}`,
    includeTagDetails: true,
    storeId,
  }

  const productApi = `product${generateQueryString(apiQueries, true)}`
  const modifiedProductApi = `${productApi}&pageType=product-listing&hasStock=1`

  const productResp = await get(modifiedProductApi)
  return productResp.data.product
}

class CartProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // When it's a function it will be invoked only at
      // client-side so local storage can be used for hydrating.
      items: {},
      sellersItems: [],
      unavailableItems: [],
      unavailableItemToDrop: [],
      pfcItems: [],
      tobeSynced: false,
      additionalCharges: {},
      feedback: {
        status: false,
        message: '',
      },
      initialLoading: true,
      asyncLoadingState: {},
      hasPromocodeApplied: [],
      switchedStoreType: this.props.switchedStoreType || '',
      isSwitchedStore: !!this.props.switchedStoreType,
      cancelSwitchStore: false,
      totalAmount: 0,
      totalDiscount: 0,
      redeemableAmount: 0,
      subTotal: 0,
      cartErrors: null,
      allCartOffers: [],
    }

    //storing the initial checkoutAddress to avoid repetitive drop off screen when storeId is upchanged
    this.oldCheckoutAddress = this.props.checkoutAddress

    this.isDefaultOrganizationAddress = !this.props.checkoutAddress.pincode

    this.update = this.update.bind(this)
    this.countOf = this.countOf.bind(this)
    this.destroy = this.destroy.bind(this)
    this.destroyAllUnavailable = this.destroyAllUnavailable.bind(this)
    this.destroyUnavailable = this.destroyUnavailable.bind(this)
    this.selectProduct = this.selectProduct.bind(this)
    this.includes = this.includes.bind(this)
    this.totalPrice = this.totalPrice.bind(this)
    this.destroyCart = this.destroyCart.bind(this)
    this.destroyAllAvailableItems = this.destroyAllAvailableItems.bind(this)
    this.getCart = this.getCart.bind(this)
    this.getCartRequestBody = this.getCartRequestBody.bind(this)
    this.storeSyncCart = this.storeSyncCart.bind(this)
    this.handleSyncCart = this.handleSyncCart.bind(this)
    this.handleDropOffCancel = this.handleDropOffCancel.bind(this)
    this.handleDropOffProceed = this.handleDropOffProceed.bind(this)
    this.handleCloseReachedStoreBulkPopup =
      this.handleCloseReachedStoreBulkPopup.bind(this)
    this.handleReachedStoreBulkProceed =
      this.handleReachedStoreBulkProceed.bind(this)
    this.dropOffProducts = this.dropOffProducts.bind(this)
    this.handleBulkPopupProceed = this.handleBulkPopupProceed.bind(this)
    this.handleBulkPopupCancel = this.handleBulkPopupCancel.bind(this)

    this.handleSyncAddress = this.handleSyncAddress.bind(this)
    this.getStoreDetails = this.getStoreDetails.bind(this)
    this.getAddressDetails = this.getAddressDetails.bind(this)
    this.getUserAddresses = this.getUserAddresses.bind(this)

    this.handleCloseUnavailablePopup =
      this.handleCloseUnavailablePopup.bind(this)

    this.handleProductQuantityConfirmation =
      this.handleProductQuantityConfirmation.bind(this)
    this.getRevisedQuantity = this.getRevisedQuantity.bind(this)

    this.clearProductCount = debounce(this.clearProductCount.bind(this), 1000)
    this.hideFeedback = this.hideFeedback.bind(this)
    this.stopCartFeedback = this.stopCartFeedback.bind(this)
    this.applyPromoCodes = this.applyPromoCodes.bind(this)
    this.checkIfCallServiceableArea = this.checkIfCallServiceableArea.bind(this)
    this.getCartAndPersistStore = this.getCartAndPersistStore.bind(this)
    this.linkPointToggleState = this.linkPointToggleState.bind(this)
    this.productCount = 0
    this.count = 0
    this.retryApiCallCount = 0
    this.message = {
      title: '',
    }
  }

  stopCartFeedback() {
    this.stopFeedback = true
    Router.onRouteChangeStart = null
    this.hideFeedback()
  }

  hideFeedback() {
    this.setState({
      feedback: {
        status: false,
        message: '',
      },
    })
  }

  clearProductCount(revisedQuantity, productCount) {
    if (this.stopFeedback) {
      return
    }

    let message

    if (revisedQuantity) {
      message = 'Cart updated'
    } else {
      if (productCount > 0) {
        message = `${productCount} ${
          productCount > 1 ? 'products' : 'product'
        } added to cart`
      } else if (productCount < 0) {
        message = `${-productCount} ${
          productCount < -1 ? 'products' : 'product'
        } removed from cart`
      }
    }

    if (message) {
      this.setState({
        feedback: {
          status: true,
          message,
        },
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // saving prev address as 2 state behind for next update and save current 2 state behind for usage below
    const twoStateBehindAddress = this.oldCheckoutAddress
    if (prevProps.checkoutAddress !== this.props.checkoutAddress) {
      this.oldCheckoutAddress = prevProps.checkoutAddress || {}
    }

    // when loggedin user goto pages that require sync call get api then will call post
    const routesToSyncCart = [
      '/promotions',
      '/brandDetails',
      '/categoryDetails',
      '/search',
      '/',
      '/product-listing',
      '/tagDetails',
      '/cart',
      '/checkout',
    ]
    if (
      !this.state.initialLoading &&
      prevProps.router?.pathname !== this.props.router?.pathname &&
      routesToSyncCart.includes(this.props.router?.pathname) &&
      this.props.isLoggedIn
    ) {
      this.getCart()
    }

    //when guest users navigate to the cart page, we need to call `POST /checkout`
    if (
      prevProps.router?.pathname !== this.props.router?.pathname &&
      this.props.router?.pathname === '/cart' &&
      !this.props.isLoggedIn
    ) {
      this.syncCart()
    }

    const {
      showUnavailablePopup,
      showBulkPopup,
      showDropOffPopup,
      isReachedStoreBulk,
      tobeSynced,
      quantityChangeCallback,
    } = this.state

    if (
      tobeSynced &&
      !showUnavailablePopup &&
      !showBulkPopup &&
      !showDropOffPopup &&
      !isReachedStoreBulk &&
      this.state.items &&
      Object.keys(this.state.items).length &&
      (Object.keys(prevState.items).length !==
        Object.keys(this.state.items).length ||
        !allEqualCounts(prevState.items, this.state.items))
    ) {
      this.syncCart(null, quantityChangeCallback)
    }

    // If address changed, sync the cart
    if (
      prevProps.checkoutAddress &&
      (prevProps.checkoutAddress.storeId !==
        this.props.checkoutAddress.storeId ||
        prevProps.checkoutAddress.pincode !==
          this.props.checkoutAddress.pincode)
    ) {
      const isCancellingSwitchStore =
        this.state.cancelSwitchStore &&
        (this.props.checkoutAddress.storeId === twoStateBehindAddress.storeId ||
          this.props.checkoutAddress.pincode === twoStateBehindAddress.pincode)

      const oldCheckoutAddress = prevProps.checkoutAddress
      const newCheckoutAddress = this.props.checkoutAddress
      const pfcStoreId = this.props.organizationData?.defaultStoreId

      if (isCancellingSwitchStore) {
        this.setState({
          cancelSwitchStore: false,
        })
      } else if (
        oldCheckoutAddress.storeId !== newCheckoutAddress.storeId &&
        // if address is changed because user go login bypass this step.
        oldCheckoutAddress.pincode
      ) {
        const switchedStoreType = determineSwitchedStoreType(
          oldCheckoutAddress,
          newCheckoutAddress,
          pfcStoreId
        )

        this.setState({
          isSwitchedStore: true,
          switchedStoreType: switchedStoreType,
        })
      }

      this.message = {
        title: 'Your cart has been updated.',
      }
      this.message.subtitle =
        'Some products in your cart have become unavailable or out of stock.'
      this.syncCart(this.props.checkoutAddress.storeId)
    }

    // If the user logs out, clear cart
    if (prevProps.isLoggedIn && !this.props.isLoggedIn) {
      this.destroyCart()
      // Clearing the saved address as well
      // this.props.updateAddress && this.props.updateAddress({})
    }

    // After the store is changed, post the cart after cart get, so that address is synced
    if (!prevState.callCheckout && this.state.callCheckout) {
      this.syncCart()
    }

    if (
      this.props.isAmendEnabled &&
      prevProps.orderToAmend?.id !== this.props.orderToAmend?.id
    ) {
      this.syncCart()
    }
  }

  async getStoreIdForLocation(location, storeId, pincode) {
    try {
      const queryString = Object.keys(location)
        .map(key => `${key}=${location[key]}`)
        .join('&')
      const orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'
      const apiUrl = `${API_URL}/serviceable-area?${queryString}&city=Singapore&pincode=${pincode}&availableStores=true&orderType=${orderType}`

      const response = await get(apiUrl)
      if (response.data && response.status === 'SUCCESS') {
        return response.data.store.hasPicking
          ? response.data.store.id
          : response.data.store.pickingStoreId
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  async componentDidMount() {
    const { remoteConfig } = this.props

    const isB2BUser = getUserType() === 'B2B'

    // Fetch and check whether the remote config object exists inside the props
    if (remoteConfig) {
      // Check reloadStoreTag and parse it into an int. Default 0
      const reloadStoreTag =
        parseInt(remoteConfig?.reloadStoreTag?.defaultValue?.value) || 0
      // Fetch cookies
      const cookies = nookies.get()
      // Flag to check whether cart syn should be done.
      let shouldCartSynFlag = true

      // If cookie has reload flag then check and compare the value from remote config.
      if (cookies.reloadStoreTag) {
        const savedReloadTag = parseInt(cookies.reloadStoreTag) || 0
        shouldCartSynFlag = savedReloadTag < reloadStoreTag
      }
      const currentCheckoutAddress = isB2BUser
        ? cookies.b2bCheckoutAddress
        : cookies.checkoutAddress

      if (currentCheckoutAddress && shouldCartSynFlag) {
        // Get address from checkoutAddress cookie, then extract the lat & long
        const address = JSON.parse(currentCheckoutAddress)

        const { location } = address
        const { storeId } = address
        const { pincode } = address

        if (location && location.latitude && location.longitude && pincode) {
          // Call service able API to fetch storeID against the lat & long
          const newStore = await this.getStoreIdForLocation(
            location,
            storeId,
            pincode
          )

          // If there is a difference in storeID's call syncCart with new storeID
          // We got from serviceableAPI
          if (storeId !== newStore) {
            this.syncCart(newStore)
            const options = {
              path: '/',
              maxAge: ONE_MONTH_IN_MS,
            }
            nookies.set({}, 'reloadStoreTag', reloadStoreTag, options)
          }
        }
      }
    }

    const { defaultItems } = this.props

    let items = {}
    /*
    login_user is being used to recognise recent logged-in users and guest users
    for the recent logged-in users, we need to merge their local carts and server cart as usual
    afterwards, we don't need to keep their local carts anymore
    for the guest users, we need to use their local carts always till they login  
    */
    const loginUser = LocalStorageService.getItem('login_user') === 'true'

    const defaultItemsData =
      typeof defaultItems === 'function' ? defaultItems() : {}

    items = loginUser ? {} : defaultItemsData
    let sellersItems = []

    if (!loginUser) {
      const localSellerCart = JSON.parse(
        LocalStorageService.getItem(
          isB2BUser ? 'b2bSellerCart' : 'sellerCart'
        ) || '[]'
      )
      sellersItems = localSellerCart
    }

    if (items && Object.keys(items).length > 0) {
      Object.keys(items).forEach(key =>
        items[key].isChecked === undefined ? true : items[key].isChecked
      )
    }

    LocalStorageService.setItem('login_user', !!this.props.isLoggedIn)

    //after sync with local cart successfully, we don't need to keep local cart anymore for logged-in users
    if (this.props.isLoggedIn) {
      LocalStorageService.removeItem('cart')
    }
    //have to load all local items at first and then process further with other API calls
    this.setState({ items, sellersItems }, async () => {
      const localItemCount = totalProductCount(this.state.items)
      if (this.props.isLoggedIn) {
        const getCartCallback = async () => {
          //if the local address has pin code and a few local cart items, we should not sync address
          //syncCart = false means getCart won't sync address and will take remote address instead
          const isSyncCart =
            !!this.props.checkoutAddress.pincode && !!localItemCount

          await this.getCart(isSyncCart)
        }

        //if an user has local cart items,
        //we should pass `isCartMerge` to do cart merge on the server with local items
        if (localItemCount) {
          const isCartMerge = true
          await this.syncCart(null, getCartCallback, isCartMerge)
        } else {
          //if an user has no local cart items, just simply get cart from the server
          await getCartCallback()
        }
      } else {
        //an user has not logged in yet
        //if this user has any local cart items, we call `syncCart` but not for cart sync but amount calculation
        if (localItemCount) {
          await this.syncCart()
        }
      }

      this.setState({
        initialLoading: false,
      })
    })
  }
  //destroy individual cart item
  destroy(product) {
    const { id } = product
    const time = getTimeStamp()
    this.lastCartUpdateTime = time
    this.setState(({ items, sellersItems }) => {
      const { [id]: value } = items
      if (sellersItems) {
        sellersItems.forEach(sellerItem => {
          if (sellerItem.items[id]) {
            sellerItem.items[id].count = 0
          }
        })
      }
      return {
        items: {
          ...items,
          [id]: {
            ...value,
            count: 0,
            t: time,
          },
        },
        sellersItems: [...sellersItems],
        tobeSynced: true,
      }
    })
  }

  selectProduct({ product, checkValue }) {
    const { id } = product

    const time = getTimeStamp()

    this.lastCartUpdateTime = time

    this.setState(({ sellersItems }) => {
      if (sellersItems) {
        sellersItems.forEach(sellerItem => {
          if (sellerItem.items[id]) {
            sellerItem.items[id].isChecked = checkValue
          }
        })
      }

      return {
        sellersItems: [...sellersItems],
      }
    })

    const proceedSelectProduct = () => {
      this.setState(
        ({ items }) => {
          items[id].isChecked = checkValue

          return {
            items: {
              ...items,
            },
          }
        },
        () => {
          this.syncCart()
        }
      )
    }

    if (this.productCheckingTimer) {
      clearTimeout(this.productCheckingTimer)
    }

    this.productCheckingTimer = setTimeout(proceedSelectProduct, 500)
  }

  destroyAllUnavailable() {
    const unavailableItemToDrop = this.state.unavailableItems.map(
      item => item.id
    )
    this.setState({
      unavailableItems: [],
      unavailableItemToDrop,
      callCheckout: true,
      tobeSynced: true,
    })
  }

  destroyUnavailable(product) {
    const { id } = product
    const { unavailableItems } = this.state
    const modifiedUnavailableItems = unavailableItems.filter(
      item => String(item.id) !== String(id)
    )
    this.setState({
      unavailableItems: modifiedUnavailableItems,
      unavailableItemToDrop: [`${id}`],
      callCheckout: true,
      tobeSynced: true,
    })
  }

  destroyAllAvailableItems() {
    let cleanedItems = { ...this.state.items }
    for (const key in cleanedItems) {
      cleanedItems = {
        ...cleanedItems,
        [key]: {
          ...cleanedItems[key],
          count: cleanedItems[key].isChecked ? 0 : cleanedItems[key].count,
        },
      }
    }
    this.setState(
      {
        items: cleanedItems,
        totalAmount: 0,
        totalDiscount: 0,
        additionalCharges: {},
        isBulk: false,
        callCheckout: false,
        tobeSynced: true,
        hasPromocodeApplied: [],
      },
      () => {
        const time = getTimeStamp()
        this.lastCartUpdateTime = time
        LocalStorageService.removeItem('cart')
      }
    )
  }

  destroyCart(isDeletingAll) {
    const unavailableItemToDrop = this.state.unavailableItems.map(
      item => item.id
    )
    let cleanedItems = { ...this.state.items }
    if (Object.keys(cleanedItems).length > 0) {
      for (const key in cleanedItems) {
        if (!cleanedItems[key]) {
          continue
        }
        cleanedItems = {
          ...cleanedItems,
          [key]: {
            ...cleanedItems[key],
            count:
              cleanedItems[key].isChecked === false && !isDeletingAll
                ? cleanedItems[key].count
                : 0,
          },
        }
      }
    }
    this.setState(
      {
        items: cleanedItems,
        unavailableItems: [],
        unavailableItemToDrop,
        totalAmount: 0,
        totalDiscount: 0,
        additionalCharges: {},
        isBulk: false,
        callCheckout: false,
        tobeSynced: true,
        hasPromocodeApplied: [],
      },
      () => {
        const time = getTimeStamp()
        this.lastCartUpdateTime = time
        LocalStorageService.removeItem('cart')
      }
    )
  }

  countOf(product) {
    const { id } = product
    if (this.state.items[id] === undefined) {
      return 0
    }
    return this.state.items[id].count
  }

  includes(product) {
    return (
      this.state.items[product.id] !== undefined &&
      this.state.items[product.id].count !== 0
    )
  }

  //applying server cart for local cart
  updateCart = items => {
    const { sellersItems } = this.state
    let updatedSellersItems = null
    if (sellersItems) {
      updatedSellersItems = [...sellersItems]
      sellersItems.forEach(sellerItem => {
        Object.keys(sellerItem.items).forEach(itemKey => {
          const statedItem = items[itemKey]
          if (sellerItem.items[itemKey] && statedItem) {
            sellerItem.items[itemKey].count = statedItem.count
            sellerItem.items[itemKey].isChecked = statedItem.isChecked
          }
        })
      })
    }
    this.setState({ items, sellersItems: updatedSellersItems }, this.syncCart)
  }

  update(product, count, callback, onDidUpdate) {
    // To handle cartSync during storeBulkThreshold
    this.isProductCountUpdatedManually = true
    if (!count || Number(count) === 0) {
      return
    }

    count = Number(count)

    this.currentCount = this.currentCount ? this.currentCount + count : count

    if (this.cartUpdateTimer) {
      clearTimeout(this.cartUpdateTimer)
    }

    this.cartUpdateTimer = setTimeout(() => {
      const originalProductCount = this.countOf(product)
      this.product = {
        ...product,
        isChecked: product.isChecked === undefined ? true : product.isChecked,
        originalProductCount,
      }
      this.count = count
      count < 0
        ? this.remove(product, callback, this.currentCount, onDidUpdate)
        : this.add(product, callback, this.currentCount, onDidUpdate)
      this.currentCount = 0
    }, 500)
  }

  add(product, callback, count = 1, onDidUpdate = () => {}) {
    const { id } = product
    let message = ''

    product = {
      ...product,
      isChecked: product.isChecked === undefined ? true : product.isChecked,
    }

    const revisedQuantity = this.getRevisedQuantity(product, count)

    if (revisedQuantity) {
      message = 'We have revised your order to the maximum quantity allowed.'
    }

    const { defaultStoreId: pfcStoreId } = this.props.organizationData || {}
    const currentStoreId = this.props?.checkoutAddress?.storeId
    const isMoreThanStoreBulk = this.checkIfMoreThanStoreBulkThreshold(
      product,
      count
    )

    const time = getTimeStamp()
    if (this.props.router?.pathname !== '/cart') {
      this.stopFeedback = false
      Router.onRouteChangeComplete = this.stopCartFeedback
      this.productCount += count
      this.clearProductCount(revisedQuantity, this.productCount)
      this.productCount = 0
    }

    this.lastCartUpdateTime = time
    this.setState(
      ({ items, sellersItems = [] }) => {
        const cartUpdated = this.count
        this.count += 1

        if (items[id] && items[id].count) {
          const currentAdIds = items[id].adIds || []
          const adIdsArr = product.adId
            ? uniq([...currentAdIds, product.adId])
            : currentAdIds

          const productCount = revisedQuantity
            ? revisedQuantity
            : items[id].count + count

          if (sellersItems) {
            sellersItems.forEach(sellerItem => {
              if (sellerItem.items[id]) {
                sellerItem.items[id].count = productCount
              }
            })
          }

          return {
            sellersItems: [...sellersItems],
            items: {
              ...items,
              [id]: {
                ...items[id],
                ...product,
                count: productCount,
                adIds: adIdsArr,
                // flag as is touched
                isTouched: true,
              },
            },
            tobeSynced: true,
            productQuantityAdjustmentMessage: message,
            cartUpdated,
          }
        }

        const productAdIds = product.adId ? [product.adId] : []
        return {
          items: {
            ...items,
            [id]: {
              ...product,
              count,
              t: time,
              adIds: productAdIds,
              // flag as is touched
              isTouched: true,
            },
          },
          tobeSynced: true,
          productQuantityAdjustmentMessage: message,
          cartUpdated,
          quantityChangeCallback: callback,
        }
      },
      () => {
        onDidUpdate(this.state.items, count)
        const { isEnabled: isDisableOriginalStoreBulkThresholdCheck } =
          getConfigFor(
            SPLIT_FEATURES.FE_DISABLE_ORIGINAL_STORE_BULK_THRESHOLD_CHECK
          )
        if (
          !isDisableOriginalStoreBulkThresholdCheck &&
          pfcStoreId !== currentStoreId &&
          isMoreThanStoreBulk
        ) {
          this.originalCheckHandleStoreBulkThreshold(product, count)
        }
      }
    )
  }

  totalPrice() {
    const {
      totalAmount = 0,
      totalDiscount = 0,
      redeemableAmount = 0,
      subTotal = 0,
      linktoggleFlag,
    } = this.state
    let calculatedTotalAmount
    calculatedTotalAmount = linktoggleFlag
      ? totalAmount - redeemableAmount
      : totalAmount

    if (calculatedTotalAmount < 0) {
      calculatedTotalAmount = 0
    }

    let redeemedAmount = 0
    if (linktoggleFlag) {
      redeemedAmount =
        totalAmount - redeemableAmount > 0 ? redeemableAmount : totalAmount
    }

    return {
      totalAmount: calculatedTotalAmount,
      redeemedAmount,
      totalDiscount,
      subTotal,
    }
  }

  remove(product, callback, count = -1, onDidUpdate = () => {}) {
    const { id } = product
    let message = ''
    const time = getTimeStamp()

    product = {
      ...product,
      isChecked: product.isChecked === undefined ? true : product.isChecked,
    }

    const revisedQuantity = this.getRevisedQuantity(product, count)
    if (revisedQuantity) {
      message = 'We have revised your order to the maximum quantity allowed.'
    }

    this.lastCartUpdateTime = time

    if (this.props.router?.pathname !== '/cart') {
      this.stopFeedback = false
      Router.onRouteChangeComplete = this.stopCartFeedback
      this.productCount -= count
      this.clearProductCount(revisedQuantity, -this.productCount)
      this.productCount = 0
    }

    this.setState(
      ({ items, sellersItems = [] }) => {
        if (!items[id]) {
          return null
        }

        let productCount = revisedQuantity
          ? revisedQuantity
          : items[id].count + count

        if (
          (Math.abs(count) < MIN_AMOUNT_ALLOWED ||
            items[id].count - Math.abs(count) < MIN_AMOUNT_ALLOWED) &&
          revisedQuantity < MIN_AMOUNT_ALLOWED
        ) {
          productCount = 0
        }

        if (sellersItems) {
          sellersItems.forEach(sellerItem => {
            if (sellerItem.items[id]) {
              sellerItem.items[id].count = productCount
            }
          })
        }
        const cartUpdated = this.count
        this.count += 1

        return {
          sellersItems: [...sellersItems],
          items: {
            ...items,
            [id]: {
              ...items[id],
              count: productCount,
              t: time,
              // flag as is touched
              isTouched: true,
            },
          },
          tobeSynced: true,
          productQuantityAdjustmentMessage: message,
          cartUpdated,
          quantityChangeCallback: callback,
        }
      },
      () => onDidUpdate(this.state.items, count)
    )
  }

  checkIfMoreThanStoreBulkThreshold(product, count = 0) {
    const storeBulkOrderThreshold =
      product?.stockOverride?.storeBulkOrderThreshold || Infinity
    const bulkOrderThreshold = product?.bulkOrderThreshold || Infinity
    const initialCount = this.countOf(product)
    const newQ = initialCount + count
    return newQ >= storeBulkOrderThreshold && newQ < bulkOrderThreshold
  }

  checkIfMoreThanGlobalBulkThreshold(product, count = 0) {
    const bulkOrderThreshold = product?.bulkOrderThreshold || Infinity
    const initialCount = this.countOf(product)
    const newQ = initialCount + count
    return newQ >= bulkOrderThreshold
  }

  async originalHandleStoreBulkThreshold() {
    const { checkoutAddress, organizationData, updateAddress } = this.props
    const {
      config: { bulkOrderSupport },
    } = organizationData || {}

    const bulkOrderTargetStore =
      bulkOrderSupport && bulkOrderSupport.targetStoreId

    const storeDetails = await this.getStoreDetails(+bulkOrderTargetStore)
    const storeName =
      (storeDetails && storeDetails[0] && storeDetails[0].name) || ''
    if (checkoutAddress.storeId !== bulkOrderTargetStore) {
      updateAddress(
        {
          ...checkoutAddress,
          storeId: +bulkOrderTargetStore,
          storeName,
        },
        true
      )
      this.isProductCountUpdatedManually = false
    }
  }

  async originalCheckHandleStoreBulkThreshold(product, qty) {
    const { config } = this.props.organizationData || {}
    const { bulkOrderSupport } = config

    const storeId = bulkOrderSupport && bulkOrderSupport.targetStoreId
    const item = [{ product }]
    const newQ = this.countOf(product) + qty

    try {
      const productResp = await getProductAvailability(item, storeId)
      const itemWithUpdatedStock = productResp && productResp[0]

      if (
        itemWithUpdatedStock &&
        itemWithUpdatedStock.storeSpecificData &&
        itemWithUpdatedStock.storeSpecificData[0] &&
        itemWithUpdatedStock.storeSpecificData[0].stock >= newQ
      ) {
        this.originalHandleStoreBulkThreshold()
      } else {
        const modifiedItems = this.lastSyncedCart
          ? getLastCartWithNewTimeStamp(this.lastSyncedCart)
          : {}
        this.setState({
          items: modifiedItems,
          tobeSynced: true,
        })
      }
    } catch (error) {
      this.setState({
        items: this.lastSyncedCart,
        tobeSynced: true,
        productQuantityAdjustmentMessage: PRODUCT_QUANTITY_ADJUSTMENT_MESSAGE,
      })
    }
  }

  async checkBulkItemAvailabilityInPfc(bulkItems, items, changes) {
    const { config } = this.props.organizationData || {}
    const { bulkOrderSupport } = config

    const storeId = bulkOrderSupport && bulkOrderSupport.targetStoreId

    try {
      const bulkItemsWithUpdatedStock = await getProductAvailability(
        bulkItems,
        storeId
      )

      if (bulkItems.length === bulkItemsWithUpdatedStock.length) {
        return { isBulk: true, items, changes }
      } else {
        const modifiedItemsAndChanges = modifyBulkItemsBasedOnPfcAvailability(
          items,
          changes
        )
        items = modifiedItemsAndChanges.items
        changes = modifiedItemsAndChanges.changes
        return { isBulk: false, items, changes }
      }
    } catch (error) {
      const modifiedItemsAndChanges = modifyBulkItemsBasedOnPfcAvailability(
        items,
        changes
      )
      items = modifiedItemsAndChanges.items
      changes = modifiedItemsAndChanges.changes
      return { isBulk: false, items, changes }
    }
  }

  getItemsExceedingStoreBulkThreshold(products) {
    return products.reduce((storeBulkProducts, item) => {
      return item.product &&
        this.checkIfMoreThanStoreBulkThreshold(item.product)
        ? [...storeBulkProducts, item.product]
        : storeBulkProducts
    }, [])
  }

  getItemsExceedingGlobalBulkThreshold(products) {
    return products.reduce((globalBulkProducts, item) => {
      return item.product &&
        this.checkIfMoreThanGlobalBulkThreshold(item.product)
        ? [...globalBulkProducts, item.product]
        : globalBulkProducts
    }, [])
  }

  getRevisedQuantity = (product, count) => {
    let updatedCount = 0
    const initialCount = this.countOf(product)
    const newQ = initialCount + count

    const { isEnabled: isOgQuantityFixEnabled } = getConfigFor(
      SPLIT_FEATURES.FE_OG_QUANTITY_INPUT_VALIDATION_FIX
    )
    if (isOgQuantityFixEnabled) {
      const maxAllowableQuantity = getMaxAllowableQuantity(product, newQ)
      if (newQ > maxAllowableQuantity) {
        return maxAllowableQuantity
      }
      return updatedCount
    }

    const bulkOrderThreshold = product.bulkOrderThreshold || Infinity
    if (newQ >= bulkOrderThreshold) {
      return updatedCount
    }

    const storeBulkOrderThreshold =
      product.stockOverride?.storeBulkOrderThreshold || Infinity
    const { defaultStoreId: pfcStoreId } = this.props.organizationData || {}
    const currentStoreId = this.props?.checkoutAddress?.storeId

    if (newQ >= storeBulkOrderThreshold && currentStoreId !== pfcStoreId) {
      return updatedCount
    }

    const maxPurchasableStock =
      product.stockOverride?.maxPurchasableStock || Infinity

    const soh =
      product.storeSpecificData[0].stock === 0
        ? 0
        : product.storeSpecificData[0].stock || Infinity

    const unlimitedStock = product.storeSpecificData[0].unlimitedStock
      ? Infinity
      : 0

    let availableStock = Math.max(soh, unlimitedStock)
    availableStock = Math.min(maxPurchasableStock, availableStock)

    if (newQ > availableStock) {
      updatedCount = availableStock
    }

    return updatedCount
  }

  handleProductQuantityConfirmation() {
    this.setState({
      productQuantityAdjustmentMessage: '',
      cartUpdated: -1,
    })
  }

  checkIfCallServiceableArea(cart = {}) {
    const { storeId, addressId } = cart
    let pincode = cart.pincode
    const { checkoutAddress, accountData } = this.props
    let shouldCallServiceableArea
    if (addressId) {
      const addresses = accountData.addresses || []
      const matchedAddress =
        addresses.find(address => String(address.id) === String(addressId)) ||
        {}
      pincode = matchedAddress.pincode
    }
    if (
      storeId &&
      (pincode === undefined ||
        String(pincode) !== String(checkoutAddress.pincode) ||
        String(storeId) !== String(checkoutAddress.storeId))
    ) {
      shouldCallServiceableArea = true
    } else {
      shouldCallServiceableArea = false
    }
    return { shouldCallServiceableArea, pincode, storeId }
  }

  getCartRequestBody(isCartMerge) {
    const { unavailableItemToDrop } = this.state
    const { accountData, orderToAmend, isAmendEnabled, router } = this.props
    const { isEnabled: isReducedQuantityEnabled } = getConfigFor(
      SPLIT_FEATURES.OOS_REDUCED_QUANTITY
    )
    const { isEnabled: isPoaFirstPointOneEnabled } = getConfigFor(
      SPLIT_FEATURES.POA_FIRST_POINT_ONE_MILESTONE
    )
    const obj = {}

    if (accountData?.id) {
      obj.customerId = accountData.id
    }

    if (this.props.checkoutAddress && this.props.checkoutAddress.storeId) {
      obj.storeId = this.props.checkoutAddress.storeId.toString()
    }

    if (this.props.checkoutAddress?.id) {
      obj.addressId = this.props.checkoutAddress.id
    }

    const requestingItems = {
      ...this.state.items,
    }

    //should not send free gifts to any requests
    Object.keys(requestingItems).forEach(itemKey => {
      if (
        requestingItems[itemKey].isFree ||
        requestingItems[itemKey].mrp === 0
      ) {
        delete requestingItems[itemKey]
      }
    })

    obj.cart = {}
    obj.cart.items = []

    for (const itemKey in requestingItems) {
      const item = requestingItems[itemKey]

      const cartItems = {
        id: itemKey,
        isChecked: item.isChecked === undefined ? true : item.isChecked,
        q: item.count ? item.count.toString() : '0',
        t: item.t,
        adIds: item.adIds,
        reason: item.reason ? item.reason : 'InStock',
      }

      if (isReducedQuantityEnabled) {
        cartItems.isUserEditQuantity = item.isTouched
      }

      obj.cart.items.push(cartItems)
    }

    obj.orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'

    if (isCartMerge) {
      obj.cart.isCartMerge = true
    }

    obj.cart.isOutOfStockEnabled = true
    obj.isOutOfStockEnabled = true

    if (isReducedQuantityEnabled) {
      obj.isReducedQuantityEnabled = true
      obj.cart.isReducedQuantityEnabled = true
    }
    obj.cart.dropUnavailableItems = unavailableItemToDrop

    obj.isCheckerEnabled = true

    obj.isSellerCheckerEnabled = true
    obj.isSellerTimeSlotEnabled = true

    obj.isDirectFulfilmentEnabled = true

    obj.poaItemDropOff = false
    if (isAmendEnabled) {
      if (isPoaFirstPointOneEnabled) {
        obj.poaVersion = '1.1'
      }
      if (orderToAmend) {
        obj.cart.amendableOrderReference = orderToAmend.referenceNumber
        if (router.pathname === '/checkout') {
          obj.poaItemDropOff = true
        }
      }
    }

    return obj
  }

  handleError = (error, asyncId) => {
    this.handleDoneAsyncLoading(asyncId, {
      error: error,
    })
  }

  isAsyncLoading = excludeAsyncId => {
    return Object.entries(this.state.asyncLoadingState)
      .filter(([asyncLoadingId]) => asyncLoadingId !== excludeAsyncId)
      .some(([, asyncLoadingState]) => asyncLoadingState)
  }

  setAsyncLoading = (extraProps, callback) => {
    const asyncId = uniqueId()

    const promise = new Promise(resolve => {
      this.setState(
        prevState => ({
          asyncLoadingState: {
            ...prevState.asyncLoadingState,
            [asyncId]: true,
          },
          ...extraProps,
        }),
        async () => {
          if (callback) {
            await callback(asyncId)
          }
          resolve(1)
        }
      )
    })

    return { asyncId, promise }
  }

  handleDoneAsyncLoading = (asyncId, extraProps) => {
    this.setState(prevState => {
      const nextAsyncLoadingState = Object.fromEntries(
        Object.entries(prevState.asyncLoadingState).filter(
          ([key]) => String(key) !== String(asyncId)
        )
      )
      return {
        asyncLoadingState: nextAsyncLoadingState,
        ...(extraProps || {}),
      }
    })
  }

  // Cart GET
  async getCart(syncCart) {
    let storeId
    const { checkoutAddress } = this.props
    if (syncCart && this.props.checkoutAddress) {
      storeId = this.props.checkoutAddress.storeId
    }
    if (!storeId && checkoutAddress.pincode) {
      await this.getCartAndPersistStore(syncCart)
    } else {
      const apiQueries = {
        orderType: getUserType() === 'B2B' ? 'B2B' : 'DELIVERY',
      }
      if (storeId) {
        apiQueries.storeId = storeId
      }
      apiQueries.isCheckerEnabled = true

      apiQueries.isSellerCheckerEnabled = true
      apiQueries.isOutOfStockEnabled = true
      apiQueries.isReducedQuantityEnabled = getConfigFor(
        SPLIT_FEATURES.OOS_REDUCED_QUANTITY
      ).isEnabled

      const cartGetApi = `cart${generateQueryString(apiQueries, true)}`

      const { asyncId } = this.setAsyncLoading({
        callCheckout: false,
      })
      try {
        // storeId not required at the time of cart sync, only required at the time of address change using PCM to show drop off, if any.
        const response = await get(cartGetApi, { headers: headers() })

        await this.handleSyncAddress(response.data, syncCart)
        this.setState(
          {
            isBoysBrigade: response.data.cart.isBoysBrigade,
          },
          () => {
            const cartLoading =
              this.state.initialLoading || this.isAsyncLoading(asyncId)
            if (cartLoading) {
              this.handleDoneAsyncLoading(asyncId)
              return
            }

            const parsedItems = getCartFromResponse(response.data.cart.items)
            if (response.data.cart.sellersItems) {
              this.handleDoneAsyncLoading(asyncId, {
                sellersItems: consolidateSellerItemData(
                  response.data.cart.sellersItems
                ),
              })
            } else {
              this.handleDoneAsyncLoading(asyncId)
            }
            this.updateCart(parsedItems)
          }
        )
      } catch (error) {
        this.handleError(error, asyncId)
      }
    }
  }

  async getCartAndPersistStore(syncCart) {
    const { checkoutAddress, updateAddress, isLoggedIn } = this.props
    //this function is only applied for logged-in users
    if (!isLoggedIn) {
      return
    }

    let serviceableData
    const that = this
    const isB2BUser = getUserType() === 'B2B'
    let addressDetails

    const apiQueries = {
      orderType: isB2BUser ? 'B2B' : 'DELIVERY',
    }
    apiQueries.isCheckerEnabled = true

    apiQueries.isSellerCheckerEnabled = true
    apiQueries.isOutOfStockEnabled = true
    apiQueries.isReducedQuantityEnabled = getConfigFor(
      SPLIT_FEATURES.OOS_REDUCED_QUANTITY
    ).isEnabled

    const cartGetApi = `cart${generateQueryString(apiQueries, true)}` // storeId not required at the time of cart sync, only required at the time of address change using PCM to show drop off, if any.

    const { promise } = this.setAsyncLoading(
      {
        shouldGetCartAndPersist: true,
        callCheckout: false,
      },
      async asyncId => {
        try {
          const response = await get(cartGetApi, {
            headers: headers(),
          })

          const cart = response.data ? response.data.cart : {}
          const { shouldCallServiceableArea, pincode, storeId } =
            this.checkIfCallServiceableArea(cart)
          if (shouldCallServiceableArea) {
            addressDetails = await that.getAddressDetails(pincode)
            const query = qs.stringify({
              latitude: addressDetails.lat,
              longitude: addressDetails.long,
              pincode,
              city: 'Singapore',
              availableStores: true,
              storeId,
              orderType: isB2BUser ? 'B2B' : 'DELIVERY',
            })
            serviceableData = await get(`serviceable-area?${query}`, {
              headers: headers(),
            })
          }

          this.setState({
            isBoysBrigade: cart.isBoysBrigade,
          })

          const parsedItems = getCartFromResponse(response.data.cart.items)

          if (response.data.cart.sellersItems) {
            this.handleDoneAsyncLoading(asyncId, {
              sellersItems: consolidateSellerItemData(
                response.data.cart.sellersItems
              ),
            })
          } else {
            this.handleDoneAsyncLoading(asyncId)
          }

          this.updateCart(parsedItems)

          if (shouldCallServiceableArea) {
            const { buildingNumber, lat, long, streetName } = addressDetails
            const addressName = pincode
              ? `${buildingNumber || ''}${
                  streetName ? ', ' + streetName : ''
                }, Singapore${pincode ? ', ' + pincode : ''}`
              : ''
            const { data } = serviceableData
            const updatedAddress = {
              ...checkoutAddress,
              storeId: data.store?.id,
              address: addressName,
              location: { latitude: lat, longitude: long },
              pincode,
              building: addressDetails.buildingName,
              storeName: data.store.name,
              storeType: data.store.storeType,
              availableStores: data.availableStores,
              pickingStoreId: data.store.pickingStoreId,
              clientId: data.store.clientId,
            }
            updateAddress(updatedAddress)
          } else {
            await this.handleSyncAddress(response.data, syncCart)
          }
        } catch (error) {
          this.handleError(error, asyncId)
        }
      }
    )

    await promise
  }

  //THIS FUNCTION IS APPLIED FOR ORIGINAL BEHAVIOURS BEFORE STORE SELECTOR CHANGES
  switchToPfcSilently = response => {
    const pfcStoreId = this.props.organizationData?.defaultStoreId

    if (
      this.state.switchedStoreType === PFCFFS &&
      String(response.data.cart.storeId) === String(pfcStoreId)
    ) {
      this.moveToStore(pfcStoreId)
      return true
    }

    if (Number(this.props.checkoutAddress.storeId) === Number(pfcStoreId)) {
      return false
    }

    const itemsExceedingStoreBulk = this.getItemsExceedingStoreBulkThreshold(
      response.data.cart.items
    )

    const itemsExceedingGlobalBulk = this.getItemsExceedingGlobalBulkThreshold(
      response.data.cart.items
    )

    const isMoreThanStoreBulk = Boolean(
      itemsExceedingStoreBulk && itemsExceedingStoreBulk.length
    )

    const isMoreThanGlobalBulk = Boolean(
      itemsExceedingGlobalBulk && itemsExceedingGlobalBulk.length
    )

    if (isMoreThanGlobalBulk || !isMoreThanStoreBulk) {
      return false
    }

    this.moveToStore(pfcStoreId)
    return true
  }

  syncCartCallback = async (updatedStoreId, callback, isCartMerge, asyncId) => {
    let api = 'cart'
    const { checkoutAddress, accountData, setAmendableOrders, isAmendEnabled } =
      this.props
    const { hasPromocodeApplied = [] } = this.state
    const isB2BUser = getUserType() === 'B2B'
    const appliedPromoCodes = [...hasPromocodeApplied]
    const cartBody = {
      ...this.getCartRequestBody(isCartMerge),
      ...getProperAddressKey(checkoutAddress, accountData),
    }

    const { [GMC_COOKIE_KEYS.OptedIn]: gmcOptedIn } = nookies.get()

    if (
      this.props.router?.pathname === '/cart' ||
      this.props.router?.pathname === '/checkout'
    ) {
      api = 'checkout'
    }

    /* istanbul ignore if  */
    if (api === 'checkout') {
      if (gmcOptedIn) {
        const gmcEnablingSplit = getExpConfig(SPLIT_FEATURES.GMC_ENABLING)
        const { couponCode } = JSON.parse(gmcEnablingSplit?.config || '{}')
        appliedPromoCodes.push(couponCode)
      }
      if (appliedPromoCodes.length) {
        cartBody.couponCodes = appliedPromoCodes
        cartBody.orderAmount = this.totalPrice().subTotal
      }

      cartBody.cardId = this.state.cardId

      if (!cartBody.cardId) {
        const defaultCard = await getDefaultCard()
        cartBody.cardId = defaultCard?.id
      }
    }

    if (updatedStoreId) {
      cartBody.storeId = updatedStoreId.toString()
    }

    if (isB2BUser) {
      cartBody.orderType = 'B2B'
    }

    cartBody.isCheckerEnabled = true

    const currentCheckoutCall = (this.latestCheckoutCall = post(api, {
      headers: headers(),
      body: JSON.stringify(cartBody),
    })
      .then(response => {
        if (isAmendEnabled && api === 'checkout') {
          setAmendableOrders(extractAmendableDeliveryOrders(response.data.cart))
        }
        this.setState(
          {
            unavailableItemToDrop: [],
          },
          () => {
            if (this.latestCheckoutCall === currentCheckoutCall) {
              const isUpdatedAddress = this.switchToPfcSilently(response)
              if (isUpdatedAddress) {
                this.handleDoneAsyncLoading(asyncId)
                return
              }
              this.handleSyncCart(response.data, cartBody, callback, asyncId)
            } else {
              this.handleDoneAsyncLoading(asyncId)
            }
          }
        )
      })
      .catch(error => {
        this.handleDoneAsyncLoading(asyncId, {
          error: error,
          items: this.lastSyncedCart || {},
        })
      }))

    return currentCheckoutCall
  }

  // Cart POST
  syncCart = async (updatedStoreId, callback, isCartMerge) => {
    const { checkoutAddress } = this.props
    // If addressId or pincode or pickupLocationId is available then make api call
    if (
      checkoutAddress.id ||
      checkoutAddress.pincode ||
      checkoutAddress.pickupLocationId ||
      checkoutAddress.storeId
    ) {
      this.itemsToDropOff = []
      const { promise } = this.setAsyncLoading(
        {
          showUnavailablePopup: false,
          showBulkPopup: false,
          showDropOffPopup: false,
          isReachedStoreBulk: false,
        },
        async asyncId =>
          this.syncCartCallback(updatedStoreId, callback, isCartMerge, asyncId)
      )
      await promise
    }
  }

  async handleSyncAddress(res, syncCart) {
    const { router } = this.props
    const isCheckoutPageRedirection =
      router && router.pathname === '/checkout' && !isEmpty(router.query)
    if (syncCart || isCheckoutPageRedirection) {
      return
    }

    const { cart } = res
    const { updateAddress } = this.props
    const orderType = getUserType() === 'B2B' ? 'B2B' : 'delivery'
    const { addressId, pincode, storeId } = cart
    let updatedAddress = {}
    if (pincode) {
      const newAddress = await this.getAddressDetails(pincode)
      const {
        buildingName,
        buildingNumber,
        lat,
        long,
        streetName,
        availableStores,
        storeType,
      } = newAddress

      const address = pincode
        ? `${buildingNumber || ''}${
            streetName ? ', ' + streetName : ''
          }, Singapore${pincode ? ', ' + pincode : ''}`
        : ''
      const building = buildingName || ''
      const location = {
        latitude: lat,
        longitude: long,
      }
      const storeDetails = await this.getStoreDetails(+storeId)
      const storeName = (storeDetails && storeDetails[0].name) || ''
      const storeClientId = (storeDetails && storeDetails[0].clientId) || ''
      updatedAddress = {
        address,
        building,
        location,
        pincode,
        storeId: +storeId,
        storeName,
        type: orderType,
        clientId: storeClientId,
        availableStores,
        storeType,
      }
    } else if (addressId) {
      const { customer } = await this.getUserAddresses()
      const { addresses = [] } = customer || {}
      const newAddress = addresses.filter(addr => +addr.id === +addressId)
      const {
        address,
        id,
        latitude,
        longitude,
        metaData,
        pincode: newPincode,
      } = newAddress[0] || {}

      const { BuildingName } = metaData || { BuildingName: '' }
      const location = {
        latitude,
        longitude,
      }

      // to get the store details
      const storeDetails = await this.getStoreDetails(+storeId)
      const storeName = (storeDetails && storeDetails[0].name) || ''
      const storeClientId = (storeDetails && storeDetails[0].clientId) || ''
      const completeAddress = address
        ? `${address + ', '}Singapore${newPincode ? ', ' + newPincode : ''}`
        : ''
      updatedAddress = {
        address: completeAddress,
        building: BuildingName,
        id,
        location,
        pincode: newPincode,
        storeId: +storeId,
        storeName,
        type: orderType,
        clientId: storeClientId,
      }
    }

    if (Object.keys(updatedAddress).length === 0) {
      return
    }
    if (
      updatedAddress &&
      updatedAddress.address !== undefined &&
      updatedAddress.availableStores === undefined
    ) {
      const { pincode: updatedPincode, location } = updatedAddress
      const query = {
        ...location,
        pincode: updatedPincode,
        city: 'Singapore',
        availableStores: true,
        orderType: getUserType() === 'B2B' ? 'B2B' : 'DELIVERY',
      }

      const { data } = await get(`serviceable-area?${qs.stringify(query)}`, {
        headers: headers(),
      })

      updatedAddress = {
        ...this.props.checkoutAddress,
        ...updatedAddress,
        storeName: data.store.name,
        storeType: data.store.storeType,
        availableStores: data.availableStores,
        pickingStoreId: data.store.pickingStoreId,
        clientId: data.store.clientId,
        storeId: data.store.id,
      }
    }
    updateAddress(updatedAddress)
  }

  async getStoreDetails(storeId) {
    return get(`store?storeId=${storeId}`, {
      headers: headers(),
    }).then(response => {
      const { store: storeDetail } = response.data || { store: null }
      return storeDetail
    })
  }

  async getAddressDetails(pincode) {
    return get(
      `${PUBLIC_API_URL}/address/search?type=all&term=${pincode}&service=cac&isBoysBrigade=true`
    ).then(response => {
      const suggestions = (response.data && response.data.addresses) || []
      return suggestions[0] || []
    })
  }

  async getUserAddresses() {
    const customerData = await get(`${getCurrentHost()}/user-profile`, {
      headers: headers(),
    })

    if (customerData) {
      return { customer: customerData }
    }

    return { customer: undefined }
  }

  handleFfsBulk = () => {
    // if onPOAflow show prevent bulk instead
    const { orderToAmend, isAmendEnabled, showPoaPreventStoreSwitchPopup } =
      this.props
    const isOnPOAFlow = isAmendEnabled && orderToAmend

    if (isOnPOAFlow) {
      showPoaPreventStoreSwitchPopup(() => {
        this.handleBulkPopupCancel()
      })
      return
    }

    // Store the bulk order response. If the user accepts, update the local cart with it.
    this.message = {
      title: 'We will need more time to get the following product(s) to you.',
      subtitle: 'Additional delivery charges may apply',
    }

    this.setState({
      showBulkPopup: true,
      isBulk: true,
    })
  }

  async handleSyncCart(respObj, cartReq, callback, asyncId) {
    //stop sync which in case of having any showing popup
    const {
      showUnavailablePopup,
      showBulkPopup,
      showDropOffPopup,
      isReachedStoreBulk,
    } = this.state
    if (
      showUnavailablePopup ||
      showBulkPopup ||
      showDropOffPopup ||
      isReachedStoreBulk
    ) {
      return
    }
    const data = respObj.cart
    // Resetting the count of retry_on_error, when api returns 200
    this.retryApiCallCount = 0

    const pfcStoreId = this.props.organizationData?.defaultStoreId

    let { changes, items, unavailableItems, isBulk, storeId, leadTime } = data

    respObj = {
      ...respObj,
      cart: {
        ...data,
        items,
        unavailableItems,
      },
    }

    // to find the bulk order products
    this.bulkOrderProducts = findBulkOrderProducts(items || [], leadTime)
    if (
      this.state.switchedStoreType === FFSPFC &&
      isBulk &&
      this.bulkOrderProducts?.length > 0
    ) {
      const isBulkItemsAvailableInPFC =
        await this.checkBulkItemAvailabilityInPfc(
          this.bulkOrderProducts,
          items,
          changes
        )
      if (!isBulkItemsAvailableInPFC.isBulk) {
        items = isBulkItemsAvailableInPFC.items
        changes = isBulkItemsAvailableInPFC.changes
        isBulk = isBulkItemsAvailableInPFC.isBulk
        storeId = this.props.checkoutAddress?.storeId
        respObj = {
          ...respObj,
          cart: {
            ...data,
            items,
            changes,
            isBulk,
            storeId,
            unavailableItems,
          },
        }
        this.setState(
          {
            callCheckout: false,
            productQuantityAdjustmentMessage:
              PRODUCT_QUANTITY_ADJUSTMENT_MESSAGE,
          },
          () => {
            this.storeSyncCart(respObj, true, asyncId)
            callback?.()
          }
        )
        return
      }
      callback?.()
    }

    const { switchedStoreType } = this.state

    //if it's a bulk order but not in PFC store
    if (
      isBulk &&
      pfcStoreId !== this.props.checkoutAddress.storeId &&
      switchedStoreType !== PFCFFS &&
      this.bulkOrderProducts?.length > 0
    ) {
      const isSwitchDisbled =
        getExpConfig(SPLIT_FEATURES.NO_SWITCH_PFC)?.treatment !== 'on'
      if (isSwitchDisbled) {
        this.handleFfsBulk()
      }
      this.storeSyncCart(respObj, false, asyncId)
      callback?.()
      return
    }
    // check for item to drop off
    const currentDroppedItems = findDropOff(changes, cartReq, [
      ...(items || []),
      ...(unavailableItems || []),
    ])

    this.props.setPOAUnaplicableItems(
      findPOAInapplicableItems(changes, [
        ...(items || []),
        ...(unavailableItems || []),
      ])
    )

    this.itemsToDropOff = currentDroppedItems

    this.itemsToDropOff = mergeAllSimilarDroppedOffItems(this.itemsToDropOff)
    if (this.itemsToDropOff && this.itemsToDropOff.length > 0) {
      if (!(this.message && this.message.title)) {
        this.message = {
          title: 'Your cart has been updated.',
          subtitle:
            'Some products in your cart have become unavailable or out of stock.',
        }
      }
      let shouldGetCartAndPersist = this.state.shouldGetCartAndPersist
      if (this.props.isAddedNewAddress && shouldGetCartAndPersist) {
        shouldGetCartAndPersist = false
      }
      this.setState({
        shouldGetCartAndPersist,
        showDropOffPopup: !this.props.isAddedNewAddress,
        showUnavailablePopup: this.props.isAddedNewAddress,
        isBulk,
        unavailableItems,
        callCheckout: false,
      })
      this.storeSyncCart(respObj, false, asyncId)
      return
    } else {
      this.setState({
        shouldGetCartAndPersist: false,
      })
    }

    if (
      !this.isProductCountUpdatedManually &&
      String(pfcStoreId) !== String(this.props.checkoutAddress.storeId)
    ) {
      //original behaviours for store bulk
      const itemsExceedingStoreBulk =
        this.getItemsExceedingStoreBulkThreshold(items)
      const { config } = this.props.organizationData || {}
      const { bulkOrderSupport } = config
      const bulkOrderSupportTargetStoreId =
        bulkOrderSupport && bulkOrderSupport.targetStoreId
      const bulkItemsInPfc = await getProductAvailability(
        itemsExceedingStoreBulk,
        bulkOrderSupportTargetStoreId
      )

      if (!bulkItemsInPfc) {
        this.storeSyncCart(respObj, false, asyncId)
        callback?.()
        return
      }

      if (bulkItemsInPfc.length === itemsExceedingStoreBulk.length) {
        this.storeSyncCart(respObj, false, asyncId)
        this.originalHandleStoreBulkThreshold()
        callback?.()
        return
      } else {
        this.isProductCountUpdatedManually = true
        this.setState({
          items: this.lastSyncedCart
            ? getLastCartWithNewTimeStamp(this.lastSyncedCart)
            : {},
          tobeSynced: true,
          productQuantityAdjustmentMessage: PRODUCT_QUANTITY_ADJUSTMENT_MESSAGE,
        })
        this.storeSyncCart(respObj, false, asyncId)
        callback?.()
        return
      }
    }

    this.storeSyncCart(respObj, false, asyncId)
    callback?.()
  }

  // Store cart response and update local cart
  // pass callCheckout true/false to enable/disable checkout call - Optional
  storeSyncCart(respObj, callCheckout, asyncId) {
    const { sellersTimeSlots } = respObj
    const data = respObj.cart || {}
    const cartErrors = respObj.errors
    const { sellersAmounts } = data
    const responseItems = data.items || []
    const unavailableItems = data.unavailableItems || []
    const totalAmount = data.youPay
    const subTotal = data.amounts?.orderAmount || 0
    const totalDiscount = data.savings
    const cartOffers = data.offers
    const isBulk = data.isBulk

    const surcharge = respObj.charge
    const orderDiscount = respObj.orderDiscount

    const additionalCharges = { ...data.amounts, surcharge, orderDiscount }

    const isMember = data.isMember
    const linkPoint = data.linkPoint
    const isBoysBrigade = data.isBoysBrigade
    const productSubstitution = data.productSubstitution
    const cardId = respObj.cardId || ''

    const cartProducts = getCartFromResponse(responseItems)
    // Storing the last synced cart for roll back in case of error
    this.lastSyncedCart = cartProducts

    const sellersItems = consolidateSellerItemData(data.sellersItems)

    modifyLocalItemsAccordingToLoginState(
      cartProducts,
      sellersItems,
      this.props.isLoggedIn
    )

    const offerConfig = this.props.organizationData?.config?.offers

    this.isProductCountUpdatedManually = false

    const appliedCouponDiscounts =
      (respObj?.couponDiscounts &&
        respObj.couponDiscounts.filter(coupon => coupon.applied)) ||
      []

    this.handleDoneAsyncLoading(asyncId, {
      cartErrors,
      items: cartProducts,
      unavailableItems,
      sellersItems: sellersItems,
      sellersAmounts: sellersAmounts,
      sellersTimeSlots: sellersTimeSlots,
      allCartOffers: cartOffers,
      ...findOtherOffers(
        responseItems,
        offerConfig,
        cartOffers,
        additionalCharges
      ),
      totalAmount,
      subTotal,
      totalDiscount,
      tobeSynced: false,
      isBulk,
      slots: respObj?.slot,
      couponDiscounts: appliedCouponDiscounts,
      callCheckout,
      additionalCharges,
      isSwitchedStore: this.state.isSwitchedStore,
      switchedStoreType: '',
      isMember,
      linkPoint,
      isBoysBrigade,
      productSubstitution,
      cardId,
    })
  }

  returnOriginalCountForBulkItems = originalItems => {
    let items = { ...originalItems }
    if (items && Object.keys(items).length) {
      for (let i = 0; i < Object.keys(items).length; i++) {
        const itemId = Object.keys(items)[i]
        const storeBulkOrderThreshold =
          items[itemId].stockOverride?.storeBulkOrderThreshold || Infinity
        const bulkOrderThreshold = items[itemId].bulkOrderThreshold || Infinity
        const minThreshold = Math.min(
          storeBulkOrderThreshold,
          bulkOrderThreshold
        )

        if (minThreshold <= items[itemId].count) {
          items = {
            ...items,
            [itemId]: {
              ...items[itemId],
              count:
                this.product &&
                this.product.originalProductCount &&
                this.product.id === itemId
                  ? this.product.originalProductCount
                  : minThreshold - 1,
            },
          }
        }
      }
    }
    return items
  }

  handleDropOffCancel() {
    this.setState(
      prevState => {
        let items = prevState.items

        items =
          this.state.switchedStoreType === PFCFFS
            ? items
            : this.returnOriginalCountForBulkItems(items)

        items = returnItemCountFromDropOffState(items, this.itemsToDropOff)

        // handle if oos enabled and return unavailable items

        items = mergeUnavailableItemsToItems(items, prevState.unavailableItems)

        return {
          ...prevState,
          items,
          shouldGetCartAndPersist: false,
          showDropOffPopup: false,
          tobeSynced: true,
          isSwitchedStore: false,
          cancelSwitchStore: true,
          switchedStoreType: '',
        }
      },
      () => {
        this.props.updateAddress(this.oldCheckoutAddress)
        this.itemsToDropOff = []
      }
    )
  }

  handleDropOffProceed() {
    this.setState({
      showDropOffPopup: false,
      shouldGetCartAndPersist: false,
      isSwitchedStore: false,
      switchedStoreType: '',
    })
  }

  handleCloseReachedStoreBulkPopup() {
    this.setState(prevState => {
      const items = this.returnOriginalCountForBulkItems(prevState.items)

      return {
        ...prevState,
        items,
        isReachedStoreBulk: false,
        tobeSynced: true,
        isSwitchedStore: false,
        switchedStoreType: '',
      }
    })
  }

  moveToStore = async storeId => {
    const storeDetails = await this.getStoreDetails(+storeId)
    const storeName =
      (storeDetails && storeDetails[0] && storeDetails[0].name) || ''
    if (this.props.checkoutAddress.storeId !== storeId) {
      this.props.updateAddress(
        {
          ...this.props.checkoutAddress,
          storeId: +storeId,
          storeName,
        },
        true //this parameter will make cart sync call
      )
      this.isProductCountUpdatedManually = false
    }
  }

  handleStoreMovingCallback = () => {
    const { defaultStoreId: pfcStoreId } = this.props.organizationData || {}
    if (pfcStoreId) {
      this.moveToStore(pfcStoreId)
    }
  }

  handleReachedStoreBulkProceed() {
    this.setState(
      {
        isReachedStoreBulk: false,
        isSwitchedStore: false,
        switchedStoreType: '',
      },
      this.handleStoreMovingCallback
    )
  }

  handleCloseUnavailablePopup() {
    this.setState(
      {
        showUnavailablePopup: false,
        isSwitchedStore: false,
        switchedStoreType: '',
      },
      this.dropOffProducts
    )
  }

  handleBulkPopupCancel() {
    this.setState(prevState => {
      const items = this.returnOriginalCountForBulkItems(prevState.items)
      return {
        ...prevState,
        items,
        showBulkPopup: false,
        isSwitchedStore: false,
        switchedStoreType: '',
        tobeSynced: true,
      }
    })
  }

  handleBulkPopupProceed() {
    this.setState(
      { showBulkPopup: false, isSwitchedStore: false, switchedStoreType: '' },
      this.handleStoreMovingCallback
    )
  }

  dropOffProducts() {
    let { items } = this.state
    const { itemsToDropOff } = this
    const t = getTimeStamp()
    if (itemsToDropOff && itemsToDropOff.length > 0) {
      items = itemsToDropOff.reduce((acc, curr) => {
        const { [curr.id]: value, ...others } = acc
        // Updating the count to allow dropoff

        return {
          ...others,
          [curr.id]: {
            ...value,
            count: curr.newQ,
            t,
          },
        }
      }, items)
      this.lastCartUpdateTime = t
      this.setState(
        {
          items,
          tobeSynced: true,
          feedback: {
            status: true,
            message: 'Cart updated',
          },
        },
        () => {
          this.itemsToDropOff = []
        }
      )
    }
  }

  applyPromoCodes(newPromoCodes) {
    if (
      this.state.hasPromocodeApplied.length !== newPromoCodes.length ||
      !_isEqual(_sortBy(newPromoCodes), _sortBy(this.state.hasPromocodeApplied))
    ) {
      this.setState({ hasPromocodeApplied: newPromoCodes }, this.syncCart)
    }
  }

  linkPointToggleState({ isRedeemed, redeemableAmount }) {
    this.setState({ linktoggleFlag: isRedeemed, redeemableAmount })
  }

  render() {
    const { children } = this.props
    const {
      showDropOffPopup,
      shouldGetCartAndPersist,
      showBulkPopup,
      showUnavailablePopup,
      productQuantityAdjustmentMessage,
      isSwitchedStore,
      isReachedStoreBulk,
      linkPoint,
      linktoggleFlag,
      feedback,
    } = this.state

    const feedbackPopupShown = feedback && feedback.status

    const storeBulkPopupShown = isReachedStoreBulk

    const dropoffPopupShown = showDropOffPopup

    const unavailablePopupShown = showUnavailablePopup

    const bulkProductPopupShown = showBulkPopup && !showDropOffPopup

    const productQuantityAdjustmentMessageShown =
      productQuantityAdjustmentMessage

    const isAnyPopupShown =
      feedbackPopupShown ||
      storeBulkPopupShown ||
      dropoffPopupShown ||
      unavailablePopupShown ||
      bulkProductPopupShown ||
      productQuantityAdjustmentMessageShown

    const cartLoading = this.state.initialLoading || this.isAsyncLoading()

    return (
      <Context.Provider
        value={{
          items: this.state.items,
          sellersItems: this.state.sellersItems,
          unavailableItems: this.state.unavailableItems,
          sellersAmounts: this.state.sellersAmounts,
          sellersTimeSlots: this.state.sellersTimeSlots,
          allCartOffers: this.state.allCartOffers,
          otherOffers: this.state.otherOffers,
          jwcOffer: this.state.jwcOffer,
          pwpOffer: this.state.pwpOffer,
          loading: cartLoading,
          slots: this.state.slots,
          couponDiscounts: this.state.couponDiscounts,
          isPwpApplicable: this.state.isPwpApplicable,
          callCheckout: this.state.callCheckout,
          additionalCharges: this.state.additionalCharges,
          quantityAdjustedmessage: this.state.cartUpdated,
          isBulk: this.state.isBulk,
          pfcItems: this.state.pfcItems,
          isMember: this.state.isMember,
          linkPoint,
          linktoggleFlag,
          isBoysBrigade: this.state.isBoysBrigade,
          productSubstitution: this.state.productSubstitution,
          cartErrors: this.state.cartErrors,

          isAnyPopupShown,

          // read-only method
          includes: this.includes,
          countOf: this.countOf,
          totalPrice: this.totalPrice,
          cartReqObj: this.getCartRequestBody,

          // mutate method
          applyPromoCodes: this.applyPromoCodes,
          linkPointToggleState: this.linkPointToggleState,
          updateCart: this.updateCart,
          update: this.update,
          destroy: this.destroy,
          destroyAllUnavailable: this.destroyAllUnavailable,
          destroyUnavailable: this.destroyUnavailable,
          selectProduct: this.selectProduct,
          destroyCart: this.destroyCart,
          destroyAllAvailableItems: this.destroyAllAvailableItems,
          syncCart: this.syncCart,
          handleSyncCart: this.handleSyncCart,
        }}
      >
        {children}

        {feedback && feedback.status && (
          <CartFeedbackWrapper
            onHide={() => {
              this.hideFeedback()
            }}
            feedback={feedback}
          />
        )}

        {isReachedStoreBulk && (
          <ReachedStoreBulkPopup
            onClose={this.handleCloseReachedStoreBulkPopup}
            onProceed={this.handleReachedStoreBulkProceed}
          />
        )}

        {showDropOffPopup && (
          <DroppedProductsPopup
            isSwitchedStore={isSwitchedStore}
            items={this.itemsToDropOff}
            message={this.message}
            shouldGetCartAndPersist={shouldGetCartAndPersist}
            onClose={this.handleDropOffCancel}
            onProceed={this.handleDropOffProceed}
          />
        )}
        {showUnavailablePopup && (
          <UnavailableProductPopup
            items={this.itemsToDropOff}
            onClose={this.handleCloseUnavailablePopup}
          />
        )}

        {showBulkPopup && !showDropOffPopup && (
          <BulkProductPopup
            message={this.message}
            bulkProducts={this.bulkOrderProducts}
            items={this.itemsToDropOff}
            onClose={this.handleBulkPopupCancel}
            onProceed={this.handleBulkPopupProceed}
          />
        )}
        {productQuantityAdjustmentMessage && (
          <ProductQuantityAdjustmentMessage
            message={productQuantityAdjustmentMessage}
            onConfirm={this.handleProductQuantityConfirmation}
          />
        )}
      </Context.Provider>
    )
  }
}

const CartProviderWrapper = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <AccountConsumer>
        {({ accountData, isLoggedIn }) => (
          <CheckoutAddressConsumer>
            {({
              checkoutAddress,
              update,
              isAddressManuallyChanged,
              isAddedNewAddress,
            }) => (
              <PostOrderAmendConsumer>
                {({
                  orderToAmend,
                  isAmendEnabled,
                  setAmendableOrders,
                  setUnaplicableItems,
                  showPoaPreventStoreSwitchPopup,
                }) => (
                  <CartProvider
                    {...props}
                    showPoaPreventStoreSwitchPopup={
                      showPoaPreventStoreSwitchPopup
                    }
                    isAmendEnabled={isAmendEnabled}
                    orderToAmend={orderToAmend}
                    setAmendableOrders={setAmendableOrders}
                    setPOAUnaplicableItems={setUnaplicableItems}
                    remoteConfig={remoteConfig}
                    accountData={accountData}
                    isLoggedIn={isLoggedIn}
                    checkoutAddress={checkoutAddress}
                    updateAddress={update}
                    isAddressManuallyChanged={isAddressManuallyChanged}
                    isAddedNewAddress={isAddedNewAddress}
                  />
                )}
              </PostOrderAmendConsumer>
            )}
          </CheckoutAddressConsumer>
        )}
      </AccountConsumer>
    )}
  </GlobalContext.Consumer>
)

CartProviderWrapper.defaultProps = {
  defaultItems: {},
}

export default withRouter(CartProviderWrapper)

export const CartConsumer = Context.Consumer
